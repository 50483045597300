import { ButtonSet, ComposedModal, Checkbox, ContentSwitcher, Switch, ComboBox, TextArea, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Tab, Tabs, TextInput, Tile, Toggle, TooltipIcon, DatePicker, DatePickerInput, Link, Tag } from 'carbon-components-react';
import React from 'react'
import Button from '../components/Button';
import { Save16, Delete16, Time16, Error16, ErrorFilled16, CheckmarkFilled16, Currency16, Information16, QrCode32, ArrowLeft16 } from '@carbon/icons-react'
import Util from '../util/Util';
import Api, { createApiWithLang } from '../session/Api';
import UIUtil from '../util/UIUtil';
import { getObjectTypeName, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CHEQUE, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_EMPLOYEE, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_REAL_ESTATE_PROPERTY, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TAX, OBJECT_TYPE_VENDOR } from '../constants/ObjectTypes';

import NoProfilePic from '../images/NoProfilePic.png';
import ThumbnailSelector from '../components/ThumbnailSelector';
import { ACCOUNT_TYPE_SALES, CHEQUE_OTHER_PARTY_TYPE_CUSTOMER, CHEQUE_OTHER_PARTY_TYPE_STAFF, CHEQUE_OTHER_PARTY_TYPE_SUPPLIER, CHEQUE_OTHER_PARTY_TYPE_VENDOR, CHEQUE_STATUS_BOUNCED, CHEQUE_STATUS_PENDING, CHEQUE_STATUS_WAITING, CHEQUE_TATUS_DEPOSITED, CHEQUE_TYPE_PAYABLE, FORM_FIELD_TYPE_ATTRIBUTES, FORM_FIELD_TYPE_BARCODES, FORM_FIELD_TYPE_BOOLEAN, FORM_FIELD_TYPE_COLOR, FORM_FIELD_TYPE_CUSTOM, FORM_FIELD_TYPE_DATE, FORM_FIELD_TYPE_DATE_RANGE, FORM_FIELD_TYPE_DISABLED_STATUS, FORM_FIELD_TYPE_EMAIL_ADDRESS, FORM_FIELD_TYPE_KV_STORE, FORM_FIELD_TYPE_LOCATION, FORM_FIELD_TYPE_MEDIA, FORM_FIELD_TYPE_MEDIA_VIDEO, FORM_FIELD_TYPE_MONEY, FORM_FIELD_TYPE_NUMBER, FORM_FIELD_TYPE_NUMBER_DECIMAL, FORM_FIELD_TYPE_PHONE_NUMBER, FORM_FIELD_TYPE_PRICE_WITH_TAX, FORM_FIELD_TYPE_RADIO, FORM_FIELD_TYPE_SERVICE, FORM_FIELD_TYPE_TABLE_LIST, FORM_FIELD_TYPE_TEXT, FORM_FIELD_TYPE_TEXT_AREA, FORM_FIELD_TYPE_TYPE_PERCENTAGE, FORM_FIELD_TYPE_TYPE_PRODUCT, TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_DIRECTION_TYPE_OUTWARD } from '../constants/Constants';
import ActiveStatusView from '../components/ActiveStatusView';
import NoteView from '../views/notes/NoteView';
import AttributeEditor from '../components/AttributeEditor';
import BarcodesEditor from '../components/BarcodesEditor';

import QRCode from 'react-qr-code';
import ProductField from '../views/product/ProductField';
import { printProductLabels } from '../pdfs/product-labels/ProductLabelsPdf';

import { Link as LR } from 'react-router-dom';
import SystemLogsListView from '../pages/system-logs/SystemLogsListView';
import { printClearanceCodeQrCode } from '../pdfs/security-clearance-card/SecurityClearanceCodePdf';
import LocationPicker from '../components/LocationPicker';

import { CirclePicker, SwatchesPicker, GithubPicker } from 'react-color'
import UOMEditor from '../pages/measurement-type/UOMEditor';
import FormQuantityField from '../pages/measurement-type/FormQuantityField';

import { hasCapabilitySupport } from '../app/Capabilities'
import { MetaTagEditor, ScriptTagEditor } from '../components/HtmlAttrEditor';
import MediaSelector from '../components/MediaSelector';
import { ServiceField } from '../views/editable-tables/ProductServiceTable';
import { KVStoreEditor } from '../components/KVStoreEditor';
import { RealEstateAmenityEditor } from '../components/RealEstateAmenityEditor';
import ErrorPage from '../components/page-messages/ErrorPage';
import { TutoringCoursesEditor } from '../components/TutoringCoursesEditor';
import { TutorListEditor } from '../components/TutorListEditor';
import CoursePaymentPackage from '../components/course-payment-package';
import { TranslateBanner } from './translate/translate-banner';
import { AdvancedNotesView } from '../views/advanced-notes/advanced-notes-view';
import { CinemaHallsListEditor } from '../views/cinema/components/cinema-halls-list-editor';
import { TheaterTypeSeatTypeListEditor } from '../views/cinema/components/theater-type-seat-type-list-editor';
import { MovieContractEditor } from '../views/cinema/components/movie-contract-editor';
import { ChequeDepositWithTransactionBtn } from '../views/transactions/cheque-deposit-with-transaction-btn';
import { FormEditor } from '../views/custom-form-editor/FormEditor';
import { DATA_TYPES, createParamsFieldsNew } from '../domain/activity/activity-blueprint';
import { fixIdForApi } from './form/form-api';
import { PasswordField } from '../components/password-field';
import { LectureComponentListEditor } from '../components/LectureComponentListEditor';
import { isMobileScreen } from '../App';
import { getAccountRole } from '../session/SessionManager';

import ReactSelect from 'react-select';

const stateValue = (key) => key + "-value";
const stateKey = key => key + "-key";

class ItemTemplate extends React.Component {

    api = Api;

    /**
     * 
     * @param {{objectType: number, skipSaveSuccesMessage: boolean, modalMode: boolean, hideGroupTitles: boolean, filterProperties: Array<string>, onSave: function(any), customTabs: Array<{title: string, component: React.Component}>, itemResult: {item: any, thumbnailSupport: boolean, mediaSupport: boolean, profilePicThumbnail: boolean, title: string, titleProperty: string, formFields: Array<{name: string, groupName: string, property: string, type: number, order: number, transparent: boolean}>}, getChildrenRequest: {parentId: number}}} dataSource 
     * @returns 
     */
    static renderTemplate(dataSource) {
        return React.createElement(ItemTemplate, dataSource);
    }

    static createLoader(objectType, api = Api) {
        return ({ itemId }) => listener => {
            const isCreating = itemId == "new";
            if (isCreating) {
                api.getItemCreator(objectType, listener)
            } else {
                api.getItem(objectType, itemId, listener)
            }
        }
    }

    /**
     * 
     * @returns {{objectType: number, skipSaveSuccesMessage: boolean, modalMode: boolean, hideGroupTitles: boolean, filterProperties: Array<string>, onSave: function(any), customTabs: Array<{title: string, component: React.Component}>, itemResult: {item: any, thumbnailSupport: boolean, mediaSupport: boolean, profilePicThumbnail: boolean, title: string, titleProperty: string, formFields: Array<{name: string, groupName: string, property: string, type: number, order: number, transparent: boolean}>}, getChildrenRequest: {parentId: number}}}
     */
    getDataSource() {
        return this.props;
    }

    constructor(props) {
        super(props);

        const itemResult = this.getDataSource().itemResult;

        const state = {
            saving: false,
            deleting: false,

            thumbnail: undefined,
            thumbnailSelectorKey: Util.newTempId(),

            media: undefined,
            mediaSelectorKey: Util.newTempId(),

            showConfirmDelete: false,
            deleteConfirmPhraseValue: "",

            openQrCodeViewer: false,
        }

        for (const formField of itemResult.formFields) {
            if (Array.isArray(itemResult.item[formField.property])) {
                state[stateValue(formField.property)] = JSON.parse(JSON.stringify(itemResult.item[formField.property]));
            } else {
                state[stateValue(formField.property)] = itemResult.item[formField.property];
            }
        }

        if (Util.isStringExists(state[stateValue(itemResult.titleProperty)])) {
            state.titleProperty = itemResult.titleProperty;
        }

        this.state = state;
        this.api = (!Util.isStringExists(props.lang) || props.lang === "EN") ? Api : createApiWithLang(props.lang);
    }

    hasLang() {
        return !(!Util.isStringExists(this.props.lang) || this.props.lang === "EN");
    }

    componentDidUpdate(_, prevState) {
        this.getFormFields().flat().filter(field => prevState[stateValue(field.property)] != this.state[stateValue(field.property)])
            .filter(field => Util.isStringExists(field.mirrorToProperty))
            .forEach(field => this.setState({ [stateValue(field.mirrorToProperty)]: this.state[stateValue(field.property)] }))
    }

    setFieldValue(property, value) {
        this.setState({ [stateValue(property)]: value })
    }

    onDeleteBtn() {
        this.setState({ deleting: true, showConfirmDelete: false })
        const id = this.getDataSource().itemResult.item.id;
        this.api.deleteItem(this.getDataSource().objectType, id, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                if (Util.isStringExists(this.getDataSource().pagePath) && !this.getDataSource().forGoBackOnDelete) {
                    this.props.history.replace({ pathname: this.getDataSource().pagePath })
                } else {
                    this.props.history.goBack();
                }

                if (this.getDataSource().onDelete) {
                    this.getDataSource().onDelete(id)
                }
            } else {
                UIUtil.showError(response.message)
                this.setState({ deleting: false })
            }
        })
    }

    isCreating() {
        return this.getDataSource().itemResult.item.id == 0;
    }

    onSaveBtn() {
        this.setState({ saving: true })

        const itemResult = this.getDataSource().itemResult;
        let update = { id: itemResult.item.id }
        for (const formField of itemResult.formFields) {
            update[formField.property] = this.state[stateValue(formField.property)];
        }

        const thumbnail = itemResult.thumbnailSupport ? this.state.thumbnail : undefined;
        const media = itemResult.mediaSupport ? this.state.media : undefined;
        // alert("med: " + itemResult.mediaSupport);

        const childrenUpdateRequest = this.getDataSource().getChildrenRequest ? {
            parentId: this.getDataSource().getChildrenRequest.parentId,
        } : undefined;


        if (this.getDataSource().onPreSave) {
            this.getDataSource().onPreSave(update)
        }
        if (this.getDataSource().shouldFixForApi) {
            update = fixIdForApi(update)
        }
        this.api.saveItem(this.getDataSource().objectType, update, childrenUpdateRequest, thumbnail, media, response => {
            if (response.status === true) {
                let savedResponse;
                if (this.getDataSource().returnItemOnSave) {
                    savedResponse = response.payload;
                    response.payload = savedResponse.id
                }

                if (!this.getDataSource().skipSaveSuccesMessage) {
                    UIUtil.showSuccess(response.message);
                }
                if (this.isCreating()) {
                    if (Util.isStringExists(this.getDataSource().pagePath)) {
                        this.props.history.replace({ pathname: this.getDataSource().pagePath + response.payload })
                    } else {
                        if (this.getDataSource().modalMode) {
                            let updateModel = { ...update }
                            for (const property in this.state) {
                                if (property.endsWith("-CUSTOM-FIELD-value")) {
                                    updateModel[property] = this.state[property]
                                }
                            }

                            if (savedResponse) {
                                updateModel = savedResponse;
                            }

                            // console.log({ response })
                            this.props.history.goBack({ ...updateModel, id: response.payload });
                        } else {
                            this.props.history.goBack();
                        }
                    }
                } else {
                    for (const property in update) {
                        itemResult.item[property] = update[property];
                    }
                    itemResult.item.id = response.payload;

                    for (const formField of itemResult.formFields) {
                        if (formField.transparent && Util.isStringExists(itemResult.item[formField.property])) {
                            itemResult.item[formField.property] = "";
                            this.setState({ [stateValue(formField.property)]: "" })
                        }
                    }

                    if (media !== undefined && thumbnail !== undefined) {
                        this.setState({ saving: false, thumbnailSelectorKey: Util.newTempId(), thumbnail: undefined, mediaSelectorKey: Util.newTempId(), media: undefined })
                    } else if (media !== undefined) {
                        this.setState({ saving: false, mediaSelectorKey: Util.newTempId(), media: undefined })
                    } else if (thumbnail !== undefined) {
                        this.setState({ saving: false, thumbnailSelectorKey: Util.newTempId(), thumbnail: undefined })
                    } else {
                        this.setState({ saving: false })
                    }

                    if (this.getDataSource().onSave !== undefined) {
                        let updateModel = { ...update }
                        for (const property in this.state) {
                            if (property.endsWith("-CUSTOM-FIELD-value")) {
                                updateModel[property] = this.state[property]
                            }
                        }

                        if (savedResponse) {
                            updateModel = savedResponse;
                        }

                        this.getDataSource().onSave(updateModel);
                        // this.getDataSource().onSave(update);
                    }

                    if (this.getDataSource().returnItemOnSave && this.getDataSource().replaceItemOnSave) {
                        for (const property in savedResponse) {
                            itemResult.item[property] = savedResponse[property];
                        }
                        itemResult.item.id = response.payload;

                        for (const formField of itemResult.formFields) {
                            this.setState({ [stateValue(formField.property)]: itemResult.item[formField.property] })
                        }
                    }
                }
            } else {
                UIUtil.showError(response.message);
                this.setState({ saving: false })
            }
        })
    }

    getFormFields() {
        let formFields = []

        const itemResult = this.getDataSource().itemResult;
        for (const group of itemResult.groups) {
            let groupFields = [{ groupName: group }];

            for (const groupFormField of itemResult.formFields.filter(field => field.groupName == group)) {
                groupFields.push(groupFormField);
            }

            formFields.push(groupFields);
        }
        return formFields;
    }

    detailsFormChangesMade() {
        const itemResult = this.getDataSource().itemResult;

        if (itemResult.mediaSupport && this.state.media !== undefined) {
            const loadingVideoExists = itemResult?.formFields?.find(field => field?.type == FORM_FIELD_TYPE_MEDIA_VIDEO && this.state[stateValue(field?.property)] === 1)
            if (loadingVideoExists) {
                return false;
            }
        }

        for (const formField of itemResult.formFields) {
            if (Array.isArray(itemResult.item[formField.property])) {
                if (!Util.arrayEqual(this.state[stateValue(formField.property)], itemResult.item[formField.property])) {
                    return true;
                }
            } else {
                if (this.state[stateValue(formField.property)] != itemResult.item[formField.property]) {
                    return true;
                }
            }
        }

        if (itemResult.thumbnailSupport && this.state.thumbnail !== undefined) {
            return true;
        }

        if (itemResult.mediaSupport && this.state.media !== undefined) {
            return true;
        }


        return false;
    }

    getField(propertyName) {
        const formFields = this.getFormFields();
        for (const formGroup of formFields) {
            for (const field of formGroup) {
                if (field.property == propertyName) {
                    return field;
                }
            }
        }
    }

    renderField(field) {
        if (field.hide) {
            return null;
        }

        if (field.restrictToFieldOption) {
            if (!field.restrictedFieldOptionIds.includes(this.state[stateValue(field.restrictedFieldOptionProperty)])) {

                if (this.getDataSource().objectType == OBJECT_TYPE_PRODUCT && field.property == "uom" && !hasCapabilitySupport("continuousStockType")) {
                    //ignore
                } else if (this.getDataSource().objectType == OBJECT_TYPE_PRODUCT && field.property == "price" && !hasCapabilitySupport("orderManagementSystem")) {
                    //ignore
                } else if (this.getDataSource().objectType == OBJECT_TYPE_PRODUCT && field.property == "rrp" && !hasCapabilitySupport("orderManagementSystem")) {
                    //ignore
                } else {
                    return null;
                }
            }
        }

        const labelText = field.transparent ? <>{field.name} <TooltipIcon tooltipText="Value is not readable. Can only be updated." renderIcon={Information16} /></> : field.name;

        switch (field.type) {
            case FORM_FIELD_TYPE_TEXT:
                if (field.transparent && field.name === "Password") {
                    return (<>
                        {/* <p>{this.state[stateValue(field.property)]}</p> */}
                        <PasswordField
                            value={this.state[stateValue(field.property)]}
                            setValue={value => {
                                this.setState({ [stateValue(field.property)]: value })
                                if (this.getDataSource().onFieldUpdate) {
                                    this.getDataSource().onFieldUpdate(field.property, value);
                                }
                            }}

                        />
                    </>)
                }

                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => {
                                this.setState({ [stateValue(field.property)]: e.target.value })
                                if (this.getDataSource().onFieldUpdate) {
                                    this.getDataSource().onFieldUpdate(field.property, e.target.value);
                                }
                            }}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_DATE:
                let typeDateValue = this.state[stateValue(field.property)];
                if (typeDateValue == 0) {
                    typeDateValue = undefined;
                }
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <DatePicker datePickerType="single"
                            dateFormat="d/m/Y"
                            value={typeDateValue}
                            onChange={e => {
                                if (e.length > 0) {
                                    this.setState({
                                        [stateValue(field.property)]: e[0].getTime(),
                                    })
                                } else {
                                    this.setState({
                                        [stateValue(field.property)]: undefined
                                    })
                                }
                            }}
                        >
                            <DatePickerInput
                                // placeholder="mm/dd/yyyy"
                                placeholder="dd/mm/yyyy"
                                labelText={labelText}
                            />
                        </DatePicker>
                    </div>
                )

            case FORM_FIELD_TYPE_DISABLED_STATUS:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <Toggle
                            labelText={labelText}
                            labelA="Disabled"
                            labelB="Active"
                            checked={!this.state[stateValue(field.property)]}
                            onChange={e => this.setState({ [stateValue(field.property)]: !e.target.checked })}
                        />
                    </div>
                )
            case FORM_FIELD_TYPE_TEXT_AREA:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextArea
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => {
                                this.setState({ [stateValue(field.property)]: e.target.value })
                                if (this.getDataSource().onFieldUpdate) {
                                    this.getDataSource().onFieldUpdate(field.property, e.target.value);
                                }
                            }}
                        />
                    </div>
                )
            case FORM_FIELD_TYPE_ATTRIBUTES:
                return (<>
                    {/* <p>{JSON.stringify(this.state[stateValue(field.property)])}</p> */}
                    <AttributeEditor defaultAttributes={this.state[stateValue(field.property)]}
                        onAttributesUpdated={attributes => this.setState({ [stateValue(field.property)]: attributes.map(attribute => ({ ...attribute, id: isNaN(attribute.id) ? undefined : attribute.id })) })} />
                </>)

            case FORM_FIELD_TYPE_KV_STORE:
                return (
                    <KVStoreEditor
                        defaultItems={this.state[stateValue(field.property)]}
                        onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                )

            case FORM_FIELD_TYPE_TABLE_LIST:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <ComboBox
                            key={field.property + "-combo-box" + this.state[stateKey(field.property)]}
                            titleText={labelText}
                            items={field.tableListItems}
                            helperText={field.help}
                            itemToString={item => item !== null ? item.title : ""}
                            selectedItem={field.tableListItems.filter(item => item.id == this.state[stateValue(field.property)])[0]}
                            //initialSelectedItem={this.state[stateValue(field.property)]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ [stateValue(field.property)]: e.selectedItem !== null ? e.selectedItem.id : 0, [stateKey(field.property)]: Util.newTempId() })
                                } else {
                                    this.setState({ [stateValue(field.property)]: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }


                            }} />
                    </div>
                )

            case FORM_FIELD_TYPE_BARCODES:
                let showTemplateLabelPrint;
                if (this.getDataSource().objectType == OBJECT_TYPE_PRODUCT || this.getDataSource().objectType == OBJECT_TYPE_PRODUCT_BUNDLE) {
                    showTemplateLabelPrint = { itemId: this.getDataSource().itemResult.item.id, productBundle: this.getDataSource().objectType == OBJECT_TYPE_PRODUCT_BUNDLE }
                }
                return (<>
                    <label className="bx--label">{'System will always generate a barcode even when no barcode is supplied'}</label>
                    <BarcodesEditor
                        showTemplateLabelPrint={showTemplateLabelPrint}
                        defaultBarcodes={this.state[stateValue(field.property)]}
                        onBarcodesUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                </>)

            case FORM_FIELD_TYPE_PHONE_NUMBER:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => {
                                this.setState({ [stateValue(field.property)]: e.target.value })
                                if (this.getDataSource().onFieldUpdate) {
                                    this.getDataSource().onFieldUpdate(field.property, e.target.value);
                                }
                            }}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_EMAIL_ADDRESS:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => {
                                this.setState({ [stateValue(field.property)]: e.target.value })
                                // console.log("few")
                                if (this.getDataSource().onFieldUpdate) {
                                    this.getDataSource().onFieldUpdate(field.property, e.target.value);
                                }
                            }}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_RADIO:
                let defIndex = 0;
                const currentValue = this.state[stateValue(field.property)];
                if (currentValue !== undefined && currentValue !== null) {
                    defIndex = field.fieldOptions.findIndex(option => option.id == currentValue);
                }

                return (
                    <div style={{ marginBottom: '1rem' }}>
                        {field.help ? (
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <label style={{ marginBottom: 0, marginRight: '0.25rem' }} className="bx--label">{labelText}</label>
                                <TooltipIcon tooltipText={field.help} renderIcon={Information16} />
                            </div>
                        ) : (
                            <label className="bx--label">{labelText}</label>
                        )}
                        <ContentSwitcher
                            //selectedIndex={this.state[stateValue(field.property)]} 
                            selectedIndex={this.state[field.property + "-Content-Switcher-index"] ?? defIndex}
                            onChange={({ name, index }) => this.setState({
                                [stateValue(field.property)]: name,
                                [field.property + "-Content-Switcher-index"]: index,
                            })}>
                            {field.fieldOptions.map(option => <Switch name={option.id} text={option.title} />)}
                        </ContentSwitcher>
                    </div>
                )

            case FORM_FIELD_TYPE_DATE_RANGE:
                let dateRangeStartValue = this.state[stateValue(field.property)];
                let dateRangeEndValue = this.state[stateValue(field.dateRangeEndProperty)];

                if (dateRangeStartValue == 0) {
                    dateRangeStartValue = new Date().getTime();
                    dateRangeStartValue = undefined;
                }

                if (dateRangeEndValue == 0) {
                    //dateRangeEndValue = new Date().getTime();
                    dateRangeEndValue = undefined
                }

                return (
                    <div //className="full-width-date-picker"
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                        {/* <label className="bx--label">{labelText}</label> */}
                        {/* <p>start: {dateRangeStartValue}</p>
                        <p>end: {dateRangeEndValue}</p> */}
                        <div key={dateRangeEndValue == Number.MAX_SAFE_INTEGER ? "single-" + field.property : "range-" + field.property} style={{ flex: 0, marginRight: '1rem' }}>
                            <DatePicker key={field.property + "-p-" + this.state[stateValue(field.property + "-picker")]} datePickerType={dateRangeEndValue == Number.MAX_SAFE_INTEGER ? "single" : "range"}
                                dateFormat="d/m/Y"
                                value={!this.state[stateValue(field.property + "-picker")] ? (dateRangeEndValue == Number.MAX_SAFE_INTEGER ? dateRangeStartValue : [dateRangeStartValue, dateRangeEndValue]) : null}
                                onOpen={() => this.setState({ [stateValue(field.property + "-picker")]: true })}
                                onClose={() => this.setState({ [stateValue(field.property + "-picker")]: true })}
                                onChange={e => {
                                    if (e.length > 1) {
                                        // console.log("update")
                                        this.setState({
                                            [stateValue(field.property)]: e[0].getTime(),
                                            [stateValue(field.dateRangeEndProperty)]: e[1].getTime()
                                        })
                                    } else if (e.length == 1) {
                                        this.setState({
                                            [stateValue(field.property)]: e[0].getTime(),
                                        })
                                    } else {
                                        this.setState({
                                            [stateValue(field.property)]: 0,
                                            [stateValue(field.dateRangeEndProperty)]: 0
                                        })
                                    }
                                }}>
                                <DatePickerInput
                                    style={{ flex: 1 }}
                                    placeholder="dd/mm/yyyy"
                                    labelText={labelText}
                                />
                                {dateRangeEndValue != Number.MAX_SAFE_INTEGER && <DatePickerInput
                                    placeholder="dd/mm/yyyy"
                                    labelText=" "
                                />}
                            </DatePicker>
                        </div>
                        <div style={{ alignSelf: 'flex-end', marginBottom: '0.25rem' }}>
                            <Checkbox id={field.property + "-checkbox"} labelText="Indefinite"
                                onChange={checked => this.setState({ [stateValue(field.dateRangeEndProperty)]: checked ? Number.MAX_SAFE_INTEGER : 0 })}
                                checked={dateRangeEndValue == Number.MAX_SAFE_INTEGER} />
                        </div>
                    </div>
                )

            case FORM_FIELD_TYPE_MONEY:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => this.setState({ [stateValue(field.property)]: e.target.value })}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_NUMBER_DECIMAL:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => this.setState({ [stateValue(field.property)]: e.target.value })}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_BOOLEAN:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <Checkbox
                            id={field.property}
                            labelText={Util.isStringExists(field.help) ? (<>{field.name} <TooltipIcon tooltipText={field.help} renderIcon={Information16} /></>) : labelText}
                            checked={this.state[stateValue(field.property)]}
                            onChange={checked => this.setState({ [stateValue(field.property)]: checked })}
                        />

                    </div>
                )

            case FORM_FIELD_TYPE_TYPE_PRODUCT:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        {/* {JSON.stringify(field)}
                        {this.state[stateValue(field.productTypeProperty)] + ""} */}
                        <ProductField
                            productFinderShowFakeTextFieldAtFirst={this.getDataSource().productFinderShowFakeTextFieldAtFirst}
                            productFinderShowReplaceWithBundle={this.getDataSource().productFinderShowReplaceWithBundle}
                            productFinderShowReplaceWithProductGroup={this.getDataSource().productFinderShowReplaceWithProductGroup}
                            labelText={labelText}
                            productId={this.state[stateValue(field.property)]}
                            productType={this.state[stateValue(field.productTypeProperty)]}
                            onProductName={name => this.setState({
                                [stateValue(field.property + "-NAME-CUSTOM-FIELD")]: name,
                            })}
                            onProductChange={({ type, id }) => this.setState({
                                [stateValue(field.productTypeProperty)]: type,
                                [stateValue(field.property)]: id,
                            })} />
                    </div>
                )

            case FORM_FIELD_TYPE_SERVICE:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <label className="bx--label">{labelText}</label>
                        <div style={{ height: 40, width: '100%', }}>
                            <ServiceField
                                id={this.state[stateValue(field.property)]}
                                name={this.state[stateValue(field.serviceNameProperty)]}
                                type={this.state[stateValue(field.productTypeProperty)]}
                                onUpdate={item => this.setState({
                                    [stateValue(field.productTypeProperty)]: item.selectedItem?.type,
                                    [stateValue(field.serviceNameProperty)]: item.name,
                                    [stateValue(field.property)]: item.selectedItem?.id,
                                })}
                            />
                        </div>
                    </div>
                )

            case FORM_FIELD_TYPE_TYPE_PERCENTAGE:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => this.setState({ [stateValue(field.property)]: e.target.value })}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_NUMBER:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <TextInput
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => this.setState({ [stateValue(field.property)]: e.target.value })}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_CUSTOM:
                switch (field.customFieldTypeName) {
                    case "productCategorySection": case "serviceCategorySection":
                        const departmentField = this.getField("departmentId");
                        const categoryField = this.getField("categoryId");
                        const subcategoryField = this.getField("subcategoryId");
                        return (
                            <div
                                key={"product-category-section-" + this.state[stateKey(field.property)]}
                                style={{ marginBottom: '1rem', display: 'flex', gap: '0.5rem' }} className="full-width-combo-box-field">

                                <ComboBox
                                    style={{ flex: 1 }}
                                    key={"product-department-combo-box"}
                                    titleText={"Department"}
                                    items={departmentField.tableListItems}
                                    itemToString={item => item !== null ? item.title : ""}
                                    selectedItem={departmentField.tableListItems.filter(item => item.id == this.state[stateValue(departmentField.property)])[0]}
                                    onChange={e => this.setState({
                                        [stateValue(departmentField.property)]: e.selectedItem !== null ? e.selectedItem.id : 0, [stateKey(field.property)]: Util.newTempId(),
                                        [stateValue(categoryField.property)]: 0,
                                        [stateValue(subcategoryField.property)]: 0,
                                    })} />

                                <ComboBox
                                    style={{ flex: 1 }}
                                    key={"product-department-combo-box"}
                                    titleText={"Category"}
                                    disabled={!Util.isNumberExist(this.state[stateValue(departmentField.property)])}
                                    items={categoryField.tableListItems.filter(item => item.parentId == this.state[stateValue(departmentField.property)])}
                                    itemToString={item => item !== null ? item.title : ""}
                                    selectedItem={categoryField.tableListItems.filter(item => item.id == this.state[stateValue(categoryField.property)])[0]}
                                    onChange={e => this.setState({
                                        [stateValue(categoryField.property)]: e.selectedItem !== null ? e.selectedItem.id : 0, [stateKey(field.property)]: Util.newTempId(),
                                        [stateValue(subcategoryField.property)]: 0,
                                    })} />

                                <ComboBox
                                    style={{ flex: 1 }}
                                    key={"product-department-combo-box"}
                                    titleText={"Subcategory"}
                                    disabled={!Util.isNumberExist(this.state[stateValue(categoryField.property)])}
                                    items={subcategoryField.tableListItems.filter(item => item.parentId == this.state[stateValue(categoryField.property)])}
                                    itemToString={item => item !== null ? item.title : ""}
                                    selectedItem={subcategoryField.tableListItems.filter(item => item.id == this.state[stateValue(subcategoryField.property)])[0]}
                                    onChange={e => this.setState({ [stateValue(subcategoryField.property)]: e.selectedItem !== null ? e.selectedItem.id : 0, [stateKey(field.property)]: Util.newTempId() })} />

                            </div>
                        )

                    case "unitOfMeasurementList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <label className="bx--label">{labelText}</label>
                                <UOMEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "realEstateAmenities":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                {this.getDataSource().objectType == OBJECT_TYPE_REAL_ESTATE_PROPERTY &&
                                    <label className="bx--label">This property will also include any amenities defined in its project</label>}
                                <RealEstateAmenityEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )


                    case "productBundleItemQuantityField":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <FormQuantityField
                                    loadPackageUnits
                                    key={this.state[stateValue("productId")] + "-pid"}
                                    productId={this.state[stateValue("productId")]}
                                    labelText={labelText}
                                    helperText={field.help}
                                    // quantity={this.state[stateValue(field.property)]} 
                                    // onQuantityUpdate={({quantity}) => this.setState({
                                    //     [stateValue(field.property)]: quantity
                                    // })} 

                                    displayQuantity={this.state[stateValue("displayQuantityUOM")]}
                                    displayQuantityConversionFactor={this.state[stateValue("displayQuantityConversionFactor")]}

                                    quantity={this.state[stateValue(field.property)]}
                                    uomId={this.state[stateValue("displayQuantityUOM")] ? -1 : this.state[stateValue("unitOfMeasurementId")]}
                                    onUpdate={({ quantity, uomId, clearDisplay }) => this.setState({
                                        [stateValue(field.property)]: quantity,
                                        [stateValue("unitOfMeasurementId")]: uomId,
                                        ...(clearDisplay ? {
                                            [stateValue("displayQuantityUOM")]: ""
                                        } : {})
                                    })}
                                />
                            </div>
                        )

                    case "productPackageUnitQuantityField":
                        const parentId = this.getDataSource().parentId;
                        //this.state[stateValue("parentId")]
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                {/* <code><pre>{JSON.stringify({parentId}, null, 4)}</pre></code> */}
                                <FormQuantityField
                                    key={parentId + "-pid"}
                                    productId={parentId}
                                    labelText={labelText}
                                    helperText={field.help}
                                    // quantity={this.state[stateValue(field.property)]} 
                                    // onQuantityUpdate={({quantity}) => this.setState({
                                    //     [stateValue(field.property)]: quantity
                                    // })} 

                                    displayQuantity={this.state[stateValue("displayQuantityUOM")]}
                                    displayQuantityConversionFactor={this.state[stateValue("displayQuantityConversionFactor")]}

                                    quantity={this.state[stateValue(field.property)]}
                                    uomId={this.state[stateValue("displayQuantityUOM")] ? -1 : this.state[stateValue("unitOfMeasurementId")]}
                                    onUpdate={({ quantity, uomId, clearDisplay }) => this.setState({
                                        [stateValue(field.property)]: quantity,
                                        [stateValue("unitOfMeasurementId")]: uomId,
                                        ...(clearDisplay ? {
                                            [stateValue("displayQuantityUOM")]: ""
                                        } : {})
                                    })}
                                />
                            </div>
                        )

                    case "productBundleItemSaleModeField":
                        if (this.state[stateValue("productType")] != OBJECT_TYPE_PRODUCT) {
                            return null;
                        }

                        return (
                            this.renderField({ ...field, type: FORM_FIELD_TYPE_RADIO })
                        )

                    case "productBundlePriceField": case "productBundleFirstDayRentalRate": case "productBundleAdditionalDaysRentalRate":
                        if (field.customFieldTypeName == "productBundlePriceField") {
                            if (!this.state[stateValue("hasRentalItem")]) {
                                return this.renderField({ ...field, type: FORM_FIELD_TYPE_PRICE_WITH_TAX })
                            }
                        } else {
                            if (this.state[stateValue("hasRentalItem")]) {
                                return this.renderField({ ...field, type: FORM_FIELD_TYPE_PRICE_WITH_TAX })
                            }
                        }
                        return null;

                    case "metaTagList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                {/* <label className="bx--label">{labelText}</label> */}
                                <MetaTagEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "scriptTagList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                {/* <label className="bx--label">{labelText}</label> */}
                                <ScriptTagEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "accountLedgerParentAccountSelector":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <ComboBox
                                    key={field.property + "-combo-box" + this.state[stateKey(field.property)]}
                                    titleText={labelText}
                                    items={field.tableListItems}
                                    helperText={field.help}
                                    itemToString={item => item !== null ? item.title : ""}
                                    selectedItem={field.tableListItems.filter(item => item.id == this.state[stateValue(field.property)])[0]}
                                    //initialSelectedItem={this.state[stateValue(field.property)]}
                                    onChange={e => {
                                        if (e.selectedItem === null) {
                                            this.setState({ [stateValue(field.property)]: e.selectedItem !== null ? e.selectedItem.id : 0, [stateKey(field.property)]: Util.newTempId() })
                                        } else {
                                            this.setState({ [stateValue(field.property)]: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                        }


                                    }} />
                            </div>
                        )

                    case "tutoringCourses":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <TutoringCoursesEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "restrictedTutors":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <TutorListEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "lectureComponentsList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <LectureComponentListEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "coursePaymentPackageList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <label className="bx--label">{labelText}</label>
                                <CoursePaymentPackage
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "cinemaHallsList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <CinemaHallsListEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "theaterTypeSeatTypesList":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <TheaterTypeSeatTypeListEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "movieContract":
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <label className="bx--label">{labelText}</label>
                                <MovieContractEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} />
                            </div>
                        )

                    case "activityInputFields":
                        const arr = (array) => Array.isArray(array) ? createParamsFieldsNew(array, []) : [];
                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                {/* <pre><code>{JSON.stringify(this.state[stateValue(field.property)], null, 2)}</code></pre> */}
                                <label className="bx--label">{labelText}</label>
                                <FormEditor itemHeaderTitle="Field"
                                    items={arr(this.state[stateValue(field.property)])}
                                    setItems={items => this.setState(prevState => ({
                                        [stateValue(field.property)]:
                                            typeof items === "function" ? items(arr(prevState[stateValue(field.property)])) : arr(items)
                                    }))}
                                    dateTypes={DATA_TYPES} />
                                {/* <label className="bx--label">{labelText}</label>
                                <MovieContractEditor
                                    defaultItems={this.state[stateValue(field.property)]}
                                    onUpdate={items => this.setState({ [stateValue(field.property)]: items.map(item => ({ ...item, id: isNaN(item.id) ? undefined : item.id })) })} /> */}
                            </div>
                        )

                    case "productManyClassificationIds": {
                        const ids = this.state[stateValue(field.property)] || [];

                        const options = this.getDataSource()?.itemResult?.item?.productClassificationOptions || [];

                        const value = ids.map(id => {
                            return options.find(option => option.value == id);
                        });
                        const setValue = selectedOptions => this.setState({ [stateValue(field.property)]: selectedOptions?.map(option => option.value) || [] });

                        return (
                            <div style={{ marginBottom: '1rem' }}>
                                <label className="bx--label">{labelText}</label>
                                <ReactSelect
                                    value={value}
                                    onChange={setValue}
                                    options={options}
                                    isMulti
                                    styles={{
                                        container: (baseStyles) => ({
                                            ...baseStyles,
                                            paddingRight: 1,
                                            background: 'transparent',
                                            height: '100%',
                                        }),
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            minHeight: '2.5rem',
                                            padding: '0 6px'
                                        }),
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 0,
                                            fontSize: '0.875rem',
                                            fontWeight: 400,
                                            lineHeight: 1.29,
                                            letterSpacing: '0.16px',
                                            outline: '2px solid transparent',
                                            outlineOffset: '-2px',
                                            width: '100%',
                                            minHeight: '2.5rem',
                                            padding: false ? '0 5px' : '0 1rem',
                                            border: 'none',
                                            borderBottom: false ? 'none' : '1px solid #8d8d8d',
                                            backgroundColor: '#f4f4f4',
                                            color: '#161616',
                                            transition: 'background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9)',

                                        }),
                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            color: 'black',
                                            height: '100%',
                                            margin: 0
                                        }),
                                        indicatorsContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            minHeight: '2.5rem',
                                        }),
                                        indicatorSeparator: state => ({
                                            display: 'none',
                                        }),

                                    }}
                                />
                            </div>
                        )
                    }


                }
                break;

            case FORM_FIELD_TYPE_PRICE_WITH_TAX:
                const fieldValue = this.state[stateValue(field.property)];
                const canCalculateTax = fieldValue ? (!isNaN(fieldValue) && ((fieldValue.trim !== undefined && fieldValue.trim() != "") || fieldValue.trim === undefined)) : false;
                let withoutTax = 0;
                if (canCalculateTax) {
                    const withTax = parseFloat(fieldValue);
                    const taxRate = field.totalTaxPercentage / 100;

                    withoutTax = withTax - ((withTax / (1 + taxRate)) * taxRate);
                }
                return (
                    <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <TextInput
                            style={{ flex: 1 }}
                            labelText={labelText}
                            helperText={field.help}
                            value={this.state[stateValue(field.property)]}
                            onChange={e => {
                                this.setState({ [stateValue(field.property)]: e.target.value })
                                if (this.getDataSource().onFieldUpdate) {
                                    this.getDataSource().onFieldUpdate(field.property, e.target.value);
                                }
                            }}
                        />

                        <div style={{ display: 'flex', height: 40, alignItems: 'center', alignSelf: 'flex-end', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            <p style={{ marginRight: '0.25rem' }}>-{field.totalTaxPercentage}%</p>
                            <label className="bx--label">(tax)</label>
                            <p style={{ marginRight: '0.25rem', marginLeft: '0.25rem' }}>=</p>
                            <h5>AED {withoutTax.toFixed(2)}</h5>
                        </div>
                    </div>
                )

            case FORM_FIELD_TYPE_LOCATION:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <LocationPicker
                            label={labelText}
                            location={this.state[stateValue(field.property)]}
                            onLocationChange={location => this.setState({ [stateValue(field.property)]: location })}
                        />
                    </div>
                )

            case FORM_FIELD_TYPE_COLOR:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <label className="bx--label">{labelText}</label>
                        <div style={{ display: 'flex' }}>
                            <div style={{ borderRadius: 5, padding: '1rem', background: '#1c1c1c' }}>
                                <CirclePicker
                                    color={this.state[stateValue(field.property)] ?? ""}
                                    onChangeComplete={e => this.setState({ [stateValue(field.property)]: e.hex })}
                                />
                            </div>
                        </div>
                    </div>
                )

            case FORM_FIELD_TYPE_MEDIA:
                // if (this.props.tryMiniFieldSize) {
                //     return null;
                // }

                const isDefaultVideo = this.getDataSource().itemResult.item[field.property];
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <label className="bx--label">{labelText}</label>
                        <MediaSelector key={this.state.mediaSelectorKey}
                            src={
                                isDefaultVideo ?
                                    this.api.getVideoMedia(this.getDataSource().objectType, this.getDataSource().itemResult.item.id) + "?k=" + this.state.mediaSelectorKey :
                                    this.api.getMedia(this.getDataSource().objectType, this.getDataSource().itemResult.item.id) + "?k=" + this.state.mediaSelectorKey
                            }
                            isVideo={this.state[stateValue(field.property)] ?? false}
                            isDefaultVideo={isDefaultVideo}
                            style={{ width: this.props.tryMiniFieldSize ? 150 : '100%', height: this.props.tryMiniFieldSize ? 150 : 300 }} onChange={({ file: media, video }) => {
                                this.setState({ media, [stateValue(field.property)]: video })
                            }} />
                    </div>
                )

            case FORM_FIELD_TYPE_MEDIA_VIDEO:
                return (
                    <div style={{ marginBottom: '1rem' }}>
                        <label className="bx--label">{labelText}</label>
                        <MediaSelector key={this.state.mediaSelectorKey}
                            src={this.api.getVideoMedia(this.getDataSource().objectType, this.getDataSource().itemResult.item.id) + "?k=" + this.state.mediaSelectorKey}
                            isVideo={this.state[stateValue(field.property)] ?? false}
                            isDefaultVideo videoOnly
                            style={{ width: this.props.tryMiniFieldSize ? 150 : '100%', height: this.props.tryMiniFieldSize ? 150 : 300 }}
                            onChange={({ file: media }) => {
                                this.setState({ media, [stateValue(field.property)]: media ? 1 : 0 })
                                // if (media) {
                                //     this.setState({ media })
                                // } else {
                                //     this.setState({ media, [stateValue(field.property)]: 0 })
                                // }
                            }}
                            onVideoLength={duration => this.setState({ [stateValue(field.property)]: duration })}
                        />
                    </div>
                )

        }
    }

    renderSystemLogsTab() {
        return (
            <div style={{ marginTop: '1rem' }}>
                {!this.isCreating() && <SystemLogsListView relatedObjectId={this.getDataSource().itemResult.item.id} />}
            </div>
        )
    }

    renderNotesTab() {
        return (
            <div style={{ marginTop: this.getDataSource().loadAdvancedNotes ? 0 : '1rem' }}>
                {!this.isCreating() && (
                    this.getDataSource().loadAdvancedNotes ? (
                        <AdvancedNotesView title="Notes/Docs" objectType={this.getDataSource().objectType} objectId={this.getDataSource().itemResult.item.id} />
                    ) : (
                        <NoteView objectType={this.getDataSource().objectType} objectId={this.getDataSource().itemResult.item.id} />
                    )
                )}
            </div>
        )
    }

    renderDetailsForm() {
        return (
            <div style={{ marginTop: this.getDataSource().noPadding ? 0 : (!this.getDataSource().modalMode ? '1rem' : 0), padding: this.getDataSource().noPadding ? '1.5rem' : undefined, display: 'flex' }}>
                {this.getDataSource().itemResult.thumbnailSupport && !this.getDataSource().hideThumbnail && !isMobileScreen && <>
                    <div>
                        <ThumbnailSelector key={this.state.thumbnailSelectorKey}
                            profilePic={this.getDataSource().itemResult.profilePicThumbnail}
                            src={this.api.getThumbnail(this.getDataSource().objectType, this.getDataSource().itemResult.item.id) + "?k=" + this.state.thumbnailSelectorKey} style={{ width: 300, height: 300 }} onChange={thumbnail => this.setState({ thumbnail })} />
                    </div>
                    <div style={{ width: '3rem' }} />
                </>}
                <div style={{ flex: 1 }}>
                    {this.getDataSource().itemResult.thumbnailSupport && !this.getDataSource().hideThumbnail && isMobileScreen && <>
                        <div>
                            <ThumbnailSelector key={this.state.thumbnailSelectorKey}
                                profilePic={this.getDataSource().itemResult.profilePicThumbnail}
                                src={this.api.getThumbnail(this.getDataSource().objectType, this.getDataSource().itemResult.item.id) + "?k=" + this.state.thumbnailSelectorKey} style={{ width: '100%', height: 300 }} onChange={thumbnail => this.setState({ thumbnail })} />
                        </div>
                        <div style={{ height: '3rem' }} />
                    </>}
                    <Form className="item-form">
                        {this.getFormFields().map(fields => fields.slice(1)
                            .filter(field => field.type >= 0 && (this.getDataSource().skipProperties === undefined ? true : !this.getDataSource().skipProperties.includes(field.property)))
                            .filter(field => field.type >= 0 && (this.getDataSource().filterProperties === undefined ? true : this.getDataSource().filterProperties.includes(field.property)))
                            .length > 0 && (
                                <FormGroup legendText={!this.getDataSource().hideGroupTitles && <p style={{ fontWeight: 'bold', color: 'black' }}>{fields[0].groupName}</p>} hasMargin>
                                    {fields.slice(1)
                                        .filter(field => field.type >= 0 && (this.getDataSource().skipProperties === undefined ? true : !this.getDataSource().skipProperties.includes(field.property)))
                                        .filter(field => field.type >= 0 && (this.getDataSource().filterProperties === undefined ? true : this.getDataSource().filterProperties.includes(field.property)))
                                        .map(field => (
                                            this.renderField(field)
                                        ))}
                                </FormGroup>
                            ))}

                        {!this.getDataSource().modalMode && <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <ButtonSet>
                                {this.renderBtns()}
                            </ButtonSet>
                        </div>}
                    </Form>
                </div>

                <ComposedModal open={this.state.showConfirmDelete} onClose={() => this.setState({ showConfirmDelete: false })}>
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    <ModalBody>
                        <p style={{ marginBottom: 5 }}>
                            Deleting this {getObjectTypeName(this.getDataSource().objectType).toLowerCase()} is irreversible and will be permanent!
                        </p>
                        <TextInput
                            data-modal-primary-focus
                            value={this.state.deleteConfirmPhraseValue}
                            onChange={e => this.setState({ deleteConfirmPhraseValue: e.target.value })}
                            onPaste={e => e.preventDefault()}
                            id="text-input-1"
                            labelText="To confirm please type out 'delete permanently'"
                            placeholder="Type out confirm phrase"
                        />
                    </ModalBody>
                    <ModalFooter
                    // primaryButtonDisabled={this.state.deleteConfirmPhraseValue != "delete permanently"} 
                    // onRequestSubmit={this.onDeleteBtn.bind(this)}
                    // danger primaryButtonText="Delete" secondaryButtonText="Cancel"
                    >
                        <Button kind="secondary" onClick={() => this.setState({ showConfirmDelete: false })}>
                            Cancel
                        </Button>
                        <Button
                            kind="danger" disabled={this.state.deleteConfirmPhraseValue != "delete permanently"}
                            onClick={this.onDeleteBtn.bind(this)}>
                            Delete
                        </Button>
                    </ModalFooter>
                </ComposedModal>
            </div>
        )
    }

    deleteWithoutPhraseModal() {
        UIUtil.confirmWithMsg(`Deleting this ${getObjectTypeName(this.getDataSource().objectType).toLowerCase()} is irreversible and will be permanent!`, () => {
            this.onDeleteBtn()
        })
    }

    renderBtns() {
        if (this.getDataSource().readOnly) {
            return null;
        }

        if (this.getDataSource().objectType == OBJECT_TYPE_CHEQUE && Util.isNumberExist(this.getDataSource().itemResult.item.finalizedDate)) {
            return null;
        }

        return (<>
            {this.isCreating() ? (
                <Button onClick={() => this.props.history.goBack()} kind="secondary" style={{ minWidth: 200 }} renderIcon={Error16} size="lg">Cancel</Button>
            ) : (
                this.getDataSource().objectType != OBJECT_TYPE_TAX &&
                !this.getDataSource().noDelete && !this.hasLang() &&
                <Button onClick={this.getDataSource().preventDeletePhraseModal ? this.deleteWithoutPhraseModal.bind(this) : (() => this.setState({ showConfirmDelete: true, deleteConfirmPhraseValue: "" }))}
                    loading={this.state.deleting} disabled={this.state.saving} style={{ minWidth: 200 }} renderIcon={Delete16} kind="danger" size="lg">Delete</Button>
            )}
            <Button onClick={this.onSaveBtn.bind(this)} loading={this.state.saving} style={{ minWidth: 200 }} renderIcon={Save16} size="lg" disabled={!this.detailsFormChangesMade() || this.state.deleting}>Save</Button>
        </>)
    }

    renderProductBundleInfo() {
        if (!this.state[stateValue('avgCost')]) {
            return null;
        }

        return (
            <div style={{ height: '100%', marginRight: '1rem', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h6>Avg Cost</h6>
                <p>AED {this.state[stateValue('avgCost')].toFixed(2)}</p>
            </div>
        )

        //const bundle = this.getDataSource().itemResult.item;
        const bundle = {
            avgCost: this.state[stateValue('avgCost')],
            currentCost: this.state[stateValue('currentCost')],
            price: this.state[stateValue('price')]
        }

        return (<>
            <div style={{ height: '100%', marginRight: '1rem', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h6>Avg Cost</h6>
                <p>AED {bundle.avgCost.toFixed(2)}</p>
            </div>
            <div style={{ height: '100%', marginRight: '1rem', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h6>Current Cost</h6>
                <p>AED {bundle.currentCost.toFixed(2)}</p>
            </div>
            <div style={{ height: '100%', marginRight: '1rem', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h6>Price</h6>
                {/* <p>AED {bundle.price.toFixed(2)}</p> */}
                <p>AED {bundle.price}</p>
            </div>
        </>)
    }

    renderProductAvgCost() {
        const product = this.getDataSource().itemResult.item;

        let avgCost = product.avgCost;
        if (!avgCost) {
            avgCost = 0;
        }

        avgCost = avgCost.toFixed(2);

        return (
            <div style={{ height: '100%', marginRight: '1rem', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h6>Avg Cost</h6>
                <p>AED {avgCost}</p>
            </div>
        )
    }

    renderChequeStatus() {
        if (this.isCreating()) {
            return null;
        }

        const cheque = this.getDataSource().itemResult.item;
        const partyType = (() => {
            switch (cheque.otherPartyType) {
                case CHEQUE_OTHER_PARTY_TYPE_SUPPLIER:
                    return OBJECT_TYPE_SUPPLIER;
                case CHEQUE_OTHER_PARTY_TYPE_CUSTOMER:
                    return OBJECT_TYPE_CUSTOMER;
                case CHEQUE_OTHER_PARTY_TYPE_VENDOR:
                    return OBJECT_TYPE_VENDOR;
                case CHEQUE_OTHER_PARTY_TYPE_STAFF:
                    return OBJECT_TYPE_ACCOUNT;
                default:
                    return null;
            }
        })();
        const partyId = (() => {
            switch (partyType) {
                case OBJECT_TYPE_SUPPLIER:
                    return cheque.supplierId;
                case OBJECT_TYPE_CUSTOMER:
                    return cheque.customerId;
                case OBJECT_TYPE_VENDOR:
                    return cheque.vendorId;
                case OBJECT_TYPE_ACCOUNT:
                    return cheque.staffId;
                default:
                    return null;
            }
        })();

        let status;
        switch (cheque.status) {
            case CHEQUE_STATUS_WAITING:
                status = <Tag renderIcon={Time16}>Waiting for due date</Tag>;
                break;
            case CHEQUE_STATUS_PENDING:
                status = (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        {/* bounce */}
                        <Button onClick={() => {
                            this.setState({ bouncingCheque: true })
                            this.api.bounceCheque(cheque.id, response => {
                                if (response.status === true) {
                                    // this.setState({bouncingCheque: false})
                                    this.props.history.go(0)
                                } else {
                                    UIUtil.showError(response.message);
                                    this.setState({ bouncingCheque: false })
                                }
                            })
                        }} loading={this.state.bouncingCheque} disabled={this.state.depositingCheque} renderIcon={ErrorFilled16} size="sm" style={{ borderRadius: 25 }} kind="danger-tertiary">Bounce</Button>

                        {/* deposit */}
                        <Button onClick={() => {
                            this.setState({ depositingCheque: true })
                            this.api.depositCheque(cheque.id, response => {
                                if (response.status === true) {
                                    // this.setState({depositingCheque: false})
                                    this.props.history.go(0)
                                } else {
                                    UIUtil.showError(response.message);
                                    this.setState({ depositingCheque: false })
                                }
                            })
                        }} loading={this.state.depositingCheque} disabled={this.state.bouncingCheque} renderIcon={CheckmarkFilled16} size="sm" style={{ borderRadius: 25 }} kind="secondary">Deposit</Button>


                        {Util.isNumberExist(partyId) && !Util.isNumberExist(cheque.usingTransactionId) &&
                            <LR to={"/transaction-creator?cqId=" + cheque.id}>
                                <Button renderIcon={Currency16} size="sm" style={{ borderRadius: 25 }} kind="primary">Deposit with Transaction</Button>
                            </LR>}
                        {/* {Util.isNumberExist(partyId) && !Util.isNumberExist(cheque.usingTransactionId) &&
                            <ChequeDepositWithTransactionBtn
                                amount={cheque.amount} chequeId={cheque.id}
                                direction={cheque.type === CHEQUE_TYPE_PAYABLE ? TRANSACTION_DIRECTION_TYPE_OUTWARD : TRANSACTION_DIRECTION_TYPE_INWARD}
                                partyType={partyType} partyId={partyId} />} */}
                    </div>
                )
                // status = (
                //     <ButtonSet style={{ width: 392, gap: 2 }}>
                //         <Button onClick={() => {
                //             this.setState({ bouncingCheque: true })
                //             this.api.bounceCheque(cheque.id, response => {
                //                 if (response.status === true) {
                //                     // this.setState({bouncingCheque: false})
                //                     this.props.history.go(0)
                //                 } else {
                //                     UIUtil.showError(response.message);
                //                     this.setState({ bouncingCheque: false })
                //                 }
                //             })
                //         }} loading={this.state.bouncingCheque}
                //             disabled={this.state.depositingCheque}
                //             kind="danger" renderIcon={ErrorFilled16}>Bounce</Button>
                //         {/* <LR style={{flex: 1}} to={"/transaction-creator?cqId=" + cheque.id}> */}
                //         <Button
                //             onClick={() => {
                //                 this.setState({ depositingCheque: true })
                //                 this.api.depositCheque(cheque.id, response => {
                //                     if (response.status === true) {
                //                         // this.setState({depositingCheque: false})
                //                         this.props.history.go(0)
                //                     } else {
                //                         UIUtil.showError(response.message);
                //                         this.setState({ depositingCheque: false })
                //                     }
                //                 })
                //             }}
                //             loading={this.state.depositingCheque}
                //             disabled={this.state.bouncingCheque} renderIcon={CheckmarkFilled16}>Deposit</Button>
                //         {/* </LR> */}
                //     </ButtonSet>
                // )
                break;
            case CHEQUE_STATUS_BOUNCED:
                status = <Tag style={{ color: 'red' }} renderIcon={ErrorFilled16}>Bounced</Tag>;
                break;
            case CHEQUE_TATUS_DEPOSITED:
                status = <Tag style={{ color: 'green' }} renderIcon={ErrorFilled16}>Deposited</Tag>;
                //status = <LR style={{width: '100%'}} to={"/transaction/" + cheque.transactionId}><Button renderIcon={Currency16}>Open Transaction</Button></LR>;
                break;
        }

        return (
            <div style={{ height: '100%', minWidth: undefined, padding: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {/* <p style={{ fontSize: 12, marginBottom: 5, opacity: 0.65, color: cheque.status == CHEQUE_TATUS_DEPOSITED ? 'green' : undefined }}>{cheque.status == CHEQUE_STATUS_PENDING ? 'Pending action' : ((cheque.status == CHEQUE_TATUS_DEPOSITED && false) ? 'Deposited' : 'Status')}</p> */}
                {status}
            </div>
        )
    }

    render() {
        if (this.getDataSource().readOnly && this.isCreating()) {
            return (
                <div style={{ height: 'calc(100vh - 6rem)' }}>
                    <ErrorPage error404 />
                </div>
            )
        }

        if (this.getDataSource().modalMode) {
            return (<>
                <ModalBody style={{ paddingRight: this.getDataSource().fullSizedModal ? '1rem' : undefined }}>
                    {this.renderDetailsForm()}
                </ModalBody>
                <ModalFooter>
                    {this.renderBtns()}
                </ModalFooter>
            </>)
        }

        return (
            <div className={"clear-tab-content"} style={{ padding: this.getDataSource().noPadding ? 0 : (this.getDataSource().modalMode ? '2rem' : '1rem'), }}>
                {!this.getDataSource().noPadding && !this.getDataSource().hideBack && <Link onClick={() => {
                    if (this.props.overrideBacktToListBtn) {
                        this.props.overrideBacktToListBtn()
                    } else {
                        this.props.history.goBack()
                    }
                }} style={{ marginBottom: '1rem', cursor: 'pointer' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</Link>}
                <Tile style={{ padding: 0, display: 'flex' }} >
                    <div style={{
                        padding: '1rem', flex: 1, ...(this.getDataSource().noPadding ? {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.25rem'
                        } : {})
                    }}>
                        {/* {'few:' + JSON.stringify(this.getDataSource().itemResult)} */}
                        {this.getDataSource().noPadding && !this.getDataSource().hideBack && <Link onClick={() => this.props.history.goBack()} style={{ marginBottom: '0rem', cursor: 'pointer' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /></Link>}
                        <h4 className="bx--data-table-header__title">{
                            this.isCreating() ? (
                                'New'
                            ) : (<>
                                {Util.isStringExists(this.getDataSource().customTitle) ? this.getDataSource().customTitle :
                                    Util.isStringExists(this.state[stateValue(this.state.titleProperty)]) ? this.state[stateValue(this.state.titleProperty)] : this.getDataSource().itemResult.title}
                                {this.getDataSource().objectType == OBJECT_TYPE_ACCOUNT && <ActiveStatusView active={!this.state[stateValue('disabled')]} />}
                            </>)
                        }</h4>
                        <p className="bx--data-table-header__description">{
                            this.getDataSource().customDetailSubtitle ? this.getDataSource().customDetailSubtitle : (
                                this.getDataSource().objectType == OBJECT_TYPE_EMPLOYEE ?
                                    ('Employee Code: ' + Util.getVoucherNumber(this.getDataSource().itemResult.item.id)) :
                                    getObjectTypeName(this.getDataSource().objectType))
                        }</p>
                        {this.getDataSource().titleBottom}
                    </div>
                    {!this.isCreating() &&
                        getAccountRole() != ACCOUNT_TYPE_SALES &&
                        this.getDataSource().objectType == OBJECT_TYPE_PRODUCT && this.renderProductAvgCost()}
                    {!this.isCreating() && this.getDataSource().objectType == OBJECT_TYPE_PRODUCT_BUNDLE && this.renderProductBundleInfo()}

                    {this.getDataSource().objectType == OBJECT_TYPE_CHEQUE && this.renderChequeStatus()}
                    {!this.isCreating() && (this.getDataSource().objectType == OBJECT_TYPE_PRODUCT || this.getDataSource().objectType == OBJECT_TYPE_ACCOUNT || this.getDataSource().objectType == OBJECT_TYPE_CUSTOMER) &&
                        <Button style={{ width: 78, justifyContent: 'center' }} kind="ghost"
                            onClick={() => {
                                if (this.getDataSource().objectType == OBJECT_TYPE_ACCOUNT && !Util.isStringExists(this.getDataSource().itemResult.item.clearanceCode)) {
                                    UIUtil.showInfo("No security clearance code set!");
                                } else {
                                    this.setState({ openQrCodeViewer: true })
                                }
                            }}
                            // renderIcon={(props) => (
                            //     <LR onClick={() => UIUtil.showError()}>
                            //         <QrCode32 {...props} style={{transform: 'scale(2)'}} />
                            //     </LR>
                            // )} 
                            className="enlarged-btn"
                            renderIcon={QrCode32}
                            hasIconOnly iconDescription="QR Code" />}

                    {!this.isCreating() && this.getDataSource().hasCustomBtn}
                </Tile>

                <Tabs onSelectionChange={this.getDataSource().onTabChanged} type="container" style={{ background: '#e0e0e0', display: (this.isCreating() || this.getDataSource().hideTabs) ? 'none' : undefined }}>
                    <Tab id="details" label="Details">
                        {this.renderDetailsForm()}
                    </Tab>
                    {this.getDataSource().customTabs !== undefined && !this.isCreating() && this.getDataSource().customTabs.map((customTab, index) => (
                        <Tab id={'custom-tab-' + index} label={customTab.title}>
                            <customTab.component item={this.getDataSource().itemResult.item} />
                        </Tab>
                    ))}
                    {/* {this.props.lang} */}
                    {!this.hasLang() &&
                        <Tab id="notes" label="Notes/Docs">
                            {this.renderNotesTab()}
                        </Tab>}
                    {!this.hasLang() &&
                        <Tab id="logs" label="System Logs">
                            {this.renderSystemLogsTab()}
                        </Tab>}
                </Tabs>

                {this.getDataSource().objectType == OBJECT_TYPE_PRODUCT &&
                    <ComposedModal size="sm" open={this.state.openQrCodeViewer} onClose={() => this.setState({ openQrCodeViewer: false })}>
                        <ModalHeader label="Product Info QR Code" title="QR Code Viewer" />
                        <ModalBody style={{ paddingRight: '1rem' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <QRCode value={window.location.origin.toString() + "/product-preview/" + this.getDataSource().itemResult.item.id} />
                            </div>
                        </ModalBody>
                        <ModalFooter
                        // primaryButtonText="Print"
                        // secondaryButtonText="Close"
                        // onRequestSubmit={() => {
                        //     printProductLabels("QR Code only", {}, [{ 
                        //         id: this.getDataSource().itemResult.item.id, 
                        //         name: this.getDataSource().itemResult.item.name,
                        //     }])
                        // }}
                        // onRequestClose={() => {
                        //     this.setState({openQrCodeViewer: false})
                        // }}
                        >
                            <Button kind="secondary" onClick={() => this.setState({ openQrCodeViewer: false })}>
                                Close
                            </Button>
                            <Button
                                onClick={() => {
                                    printProductLabels("QR Code only", {}, [{
                                        id: this.getDataSource().itemResult.item.id,
                                        name: this.getDataSource().itemResult.item.name,
                                    }])
                                }}>
                                Print
                            </Button>
                        </ModalFooter>
                    </ComposedModal>}

                {this.getDataSource().objectType == OBJECT_TYPE_ACCOUNT && !this.isCreating() && Util.isStringExists(this.getDataSource().itemResult.item.clearanceCode) &&
                    <ComposedModal size="sm" open={this.state.openQrCodeViewer} onClose={() => this.setState({ openQrCodeViewer: false })}>
                        <ModalHeader label="Security Clearance QR Code" title="QR Code Viewer" />
                        <ModalBody style={{ paddingRight: '1rem' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <QRCode value={this.getDataSource().itemResult.item.clearanceCode} />
                            </div>
                        </ModalBody>
                        <ModalFooter
                        // primaryButtonText="Print"
                        // secondaryButtonText="Close"
                        // onRequestSubmit={() => {
                        //     printClearanceCodeQrCode(this.getDataSource().itemResult.item.clearanceCode)
                        // }}
                        // onRequestClose={() => {
                        //     this.setState({openQrCodeViewer: false})
                        // }}
                        >
                            <Button kind="secondary" onClick={() => this.setState({ openQrCodeViewer: false })}>
                                Close
                            </Button>
                            <Button
                                onClick={() => printClearanceCodeQrCode(this.getDataSource().itemResult.item.clearanceCode)}>
                                Print
                            </Button>
                        </ModalFooter>
                    </ComposedModal>}


                {this.getDataSource().objectType == OBJECT_TYPE_CUSTOMER && !this.isCreating() &&
                    <ComposedModal size="sm" open={this.state.openQrCodeViewer} onClose={() => this.setState({ openQrCodeViewer: false })}>
                        <ModalHeader label="Customer QR Code" title="QR Code Viewer" />
                        <ModalBody style={{ paddingRight: '1rem' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <QRCode value={this.getDataSource().itemResult.item.qrCode} />
                            </div>
                        </ModalBody>
                        <ModalFooter
                        // primaryButtonText="Print"
                        // secondaryButtonText="Close"
                        // onRequestSubmit={() => {
                        //     printClearanceCodeQrCode(this.getDataSource().itemResult.item.qrCode)
                        // }}
                        // onRequestClose={() => {
                        //     this.setState({openQrCodeViewer: false})
                        // }}
                        >
                            <Button kind="secondary" onClick={() => this.setState({ openQrCodeViewer: false })}>
                                Close
                            </Button>
                            <Button
                                onClick={() => printClearanceCodeQrCode(this.getDataSource().itemResult.item.qrCode)}>
                                Print
                            </Button>
                        </ModalFooter>
                    </ComposedModal>}

                <div style={{ height: '4rem' }} />
            </div>
        )
    }

}

export default ItemTemplate;