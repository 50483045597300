import BarcodesEditor from "../components/BarcodesEditor";
import Util from "../util/Util";


export function InvItemContent({ data }) {
    return <div style={{ padding: '2rem' }}>

        <h6 style={{ marginTop: 0, marginBottom: '0.25rem' }}>Details</h6>
        <div className="cargo-inv-item-content-details" style={{ display: 'grid', gap: '1rem', }}>
            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    CRN No
                </label>
                <p>{Util.getVoucherNumber(data.crnId)}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Item ID
                </label>
                <p>{data.itemId}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Code
                </label>
                <p>{data.code}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Description
                </label>
                <p>{data.description}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Supplier
                </label>
                <p>{data.supplier}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    H.S. Code
                </label>
                <p>{data.hsCode}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Country of Origin
                </label>
                <p>{data.origin}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Width (M)
                </label>
                <p>{data.width}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Height (M)
                </label>
                <p>{data.height}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Length (M)
                </label>
                <p>{data.length}</p>
            </div>

            <div>
                <label className="bx--label" style={{ opacity: 0.85, marginBottom: 0 }}>
                    Weight (KG)
                </label>
                <p>{data.weight}</p>
            </div>
        </div>

        <h6 style={{ marginTop: '1rem', marginBottom: '0.25rem' }}>Barcodes</h6>
        <BarcodesEditor
            readonly
            // showTemplateLabelPrint={{ itemId: product.id, productBundle: false }}
            defaultBarcodes={data.barcodes}
            hideTypeDetails
            onBarcodesUpdate={items => { }} />

    </div>
}