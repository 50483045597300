import React from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { TABLE_RELATION_TYPE_MANY_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_LOYALTY_CARD, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import { Add16, Checkmark16 } from '@carbon/icons-react';
import { renderNode } from '../../components/imperative-render';
import { SidePanel } from '../../templates/draft/components/side-panel';
import { LockScroll } from '../../hooks/useLockScroll';
import { ReportPage } from '../../reporting-engine/base/report-page';
import UIUtil from '../../util/UIUtil';

class BulkIssueComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idsToAdd: []
        }
    }

    async onSaveBtn() {
        const confirm = await UIUtil.confirmPrompt({
            title: "Bulk Issue",
            message: `Are you sure you want to issue ${this.state.idsToAdd.length} cards to selected customers?`
        })

        if (!confirm) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.bulkIssueLoyaltyCard({
                loyaltyCardId: this.props.loyaltyCardId,
                customerIds: this.state.idsToAdd
            }, listener));
            if (!success) {
                return;
            }

            window.location.reload();
        } finally {
            stopLoading();
        }
    }

    render() {
        return (
            <SidePanel md2 backBtn onClose={this.props.onClose}>
                <LockScroll />
                <div style={{ height: '3rem', display: 'flex', alignItems: 'center', gap: '1rem', paddingInlineStart: '3rem', paddingInlineEnd: '1rem' }}>
                    <h4>Select Customers</h4>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Bulk card issue tool</p>
                    <div style={{ flex: 1 }} />
                    <Button onClick={this.onSaveBtn.bind(this)} disabled={!this.state.idsToAdd.length} size="sm" style={{ borderRadius: 5 }} renderIcon={Checkmark16}>Save</Button>
                </div>
                <div style={{ height: 'calc(100% - 3rem)' }}>
                    <ReportPage reportName="V1CustomerList" embeddedMode componentMode
                        selectionMode={{
                            isSelected: id => this.state.idsToAdd?.includes(id),
                            select: id => this.setState(prevState => ({ idsToAdd: [...prevState.idsToAdd, id] })),
                            unselect: removeId => this.setState(prevState => ({ idsToAdd: prevState.idsToAdd?.filter(id => id != removeId) })),
                            isAnySelected: () => this.state.idsToAdd?.length > 0,
                            clearSelection: () => this.setState({ idsToAdd: [] })
                        }}
                    />
                </div>
            </SidePanel>
        )
    }
}

const IssuesList = ({ item }) => {
    const history = useHistory();

    const onBulkIssueBtn = () => {
        renderNode(onClose => (
            <BulkIssueComponent loyaltyCardId={item.id} onClose={onClose} />
        ))
    }

    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getChildrenRequest: {
                    parentId: item.id,
                },
                embedded: true,
                title: "Issues",
                subTitle: "Card issues",
                objectType: OBJECT_TYPE_LOYALTY_CARD_ISSUE,
                pagePath: "/loyalty-cards-issue/",
                history: history,
                customPreCreateBtnArea: <>
                    <Button onClick={onBulkIssueBtn} renderIcon={Add16} className="green-button">Bulk Issue</Button>
                </>
            })}
        </div>
    )
}


class LoyaltyCardDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_LOYALTY_CARD, listener)
            } else {
                Api.getItem(OBJECT_TYPE_LOYALTY_CARD, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_LOYALTY_CARD,
                    itemResult: this.state.itemResult,
                    pagePath: "/loyalty-cards/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Issues",
                            component: IssuesList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(LoyaltyCardDetailPage);