import { AccountingEntitiesReport } from "./accounting-entities-report";
import { CRVSummary } from "./crv-summary";
import { CRVDetail } from "./crv-detail";
import { CustomerBalanceReport } from "./customer-balance-report";
import { FoodList } from "./food-list";
import { FreeSalesReport } from "./free-sales-report";
import { FundTransferList } from "./fund-transfer-list";
import { IngredientList } from "./ingredient-list";
import { LeadsReport } from "./leads-report";
import { LedgerReport } from "./ledger-report";
import { LmsNotificationList } from "./lms-notification-list";
import { LoadingListReport } from "./loading-list-report";
import { MyLmsNotificationList } from "./my-lms-notification-list";
import { OnlineLearnersList } from "./online-learners-list";
import { ProductList } from "./product-list";
import { ProductMergeReport } from "./product-merge-report";
import { ProductStockReport } from "./product-stock-report";
import { PurchaseReport } from "./purchase-report";
import { SalesByCustomerDetail } from "./sales-by-customer-detail";
import { SalesByCustomerSummary } from "./sales-by-customer-summary";
import { SalesByItemSummary } from "./sales-by-item-summary";
import { SalesReport } from "./sales-report";
import { ShipmentList } from "./shipment-list";
import { ShipmentMasterList } from "./shipment-master-list";
import { StockQtyByLocReport } from "./stock-qty-by-loc-report";
import { StockReport } from "./stock-report";
import { StockValuationByLocReport } from "./stock-valuation-by-loc-report";
import { StockValuationReport } from "./stock-valuation-report";
import { SupplierBalanceReport } from "./supplier-balance-report";
import { SystemLogsReport } from "./system-logs-report";
import { CustomerBalanceSummary } from './customer-balance-summary';
import { CustomerAgingSummary } from './customer-aging-summary';
import { SalesByCustomerType } from './sales-by-customer-type';
import { SalesOrderQuantity } from './sales-order-quantity';
import { SalesOrderValuation } from './sales-order-valuation';
import { SalesByItemDetail } from './sales-by-item-detail';
import { BillsBySupplierSummary } from './bills-by-supplier-summary';
import { BillsBySupplierDetail } from './bills-by-supplier-detail';
import { BillsByItemSummary } from './bills-by-item-summary';
import { BillsByItemDetail } from './bills-by-item-detail';
import { SupplierBalanceSummary } from './supplier-balance-summary';
import { SupplierBalanceDetail } from './supplier-balance-detail';
import { SupplierAgingSummary } from './supplier-aging-summary';
import { StockByLocation } from './stock-by-location';
import { InvValuationSummary } from './inv-valuation-summary';
import { InvValuationDetail } from './inv-valuation-detail';
import { StockStatusByItem } from './stock-status-by-item';
import { ConsumptionReport } from './consumption-report';
import { ConsumptionByLocation } from './consumption-by-location';
import { BalanceSheet } from './balance-sheet';
import { ProfitLoss } from './profit-loss';
import { TrialBalance } from './trial-balance';
import { VATSummary } from './vat-summary';
import { VATDetail } from './vat-detail';
import { AccountTree } from './account-tree';
import { V2SalesList } from './v2sales-list';
import { CustomerBalanceDetail } from './customer-balance-detail';
import { V2CustomersList } from './v2customers-list';
import { CustomerList } from './customer-list';
import { VendorList } from './vendor-list';
import { EmployeeList } from './employee-list';
import { CustomerTypeList } from './customer-type-list';
import { InventoryLocationList } from './inventory-location-list';
import { V2SalesReport } from './v2sales-report';
import { CostingAudit } from './costing-audit';
import { V2StockReport } from './v2stock-report';
import { PhysicalInventoryWorksheet } from './physical-inventory-worksheet';
import { V2BillList } from './v2bill-list';
import { StockAdjustmentList } from './stock-adjustment-list';
import { StockTransferList } from './stock-transfer-list';
import { StockWasteList } from './stock-waste-list';
import { SalesByCustomerDetailPerItem } from './sales-by-customer-detail-per-item';
import { BillsBySupplierPerItem } from './bills-by-supplier-per-item';
import { V2BillsReport } from './v2bills-report';
import { PackingList } from './packing-list';
import { PurchaseOrderList } from './purchase-order-list';
import { CRVAccountsBalance } from './crvaccounts-balance';
import { ConsumptionByService } from './consumption-by-service';
import { SalesReportDetail } from './sales-report-detail';
import { SalesConsumptionDetail } from './sales-consumption-detail';
import { V2LedgerReport } from './v2ledger-report';
import { RestOrderList } from './rest-order-list';
import { SalesBySupplier } from './sales-by-supplier';
import { SerialNoReport } from './serial-no-report';
import { SalesByItem } from "./sales-by-item";
import { PaymentMethodReport } from './payment-method-report';
import { PaymentMethodDetail } from './payment-method-detail';
import { BookClosings } from './book-closings';
import { SalesOrderReport } from './sales-order-report';
import { CustomerOutstandingReport } from './customer-outstanding-report';
import { SupplierOutstandingReport } from './supplier-outstanding-report';
import { FnbEventList } from "./fnb-event-list";
import { Productions } from './productions';
import { SalesOrderConsumption } from './sales-order-consumption';
import { CogsTrailReport } from './cogs-trail-report';
import { InvRequests } from './inv-requests';
import { ActiveInvRequests } from './active-inv-requests';
import { AllInvRequests } from './all-inv-requests';
import { V1PoList } from './v1po-list';
import { CourierReceipt } from './courier-receipt';
import { CargoStockReport } from './cargo-stock-report';
import { CargoLocationReport } from './cargo-location-report';
import { CargoReceiptNoteList } from './cargo-receipt-note-list';
import { CargoIssueForTransferList } from './cargo-issue-for-transfer-list';
import { CargoReceiptFromTransferList } from './cargo-receipt-from-transfer-list';
import { CargoInventory } from './cargo-inventory';
import { ItemConditionNamesList } from './item-condition-names-list';
import { InvAdjustmentList } from './inv-adjustment-list';
import { LastStockAdjustReport } from "./last-stock-adjust-report";
import { V1CustomerList } from "./v1customer-list";

const REPORTS = {
    "SalesReport": SalesReport,
    "FreeSalesReport": FreeSalesReport,
    "LeadsReport": LeadsReport,
    "LedgerReport": LedgerReport,
    "AccountingEntitiesReport": AccountingEntitiesReport,
    "StockReport": StockReport,
    "ProductStockReport": ProductStockReport,
    "SystemLogsReport": SystemLogsReport,
    "ProductMergeReport": ProductMergeReport,
    "ShipmentList": ShipmentList,
    "ShipmentMasterList": ShipmentMasterList,
    "StockQtyByLocReport": StockQtyByLocReport,
    "ProductList": ProductList,
    "PurchaseReport": PurchaseReport,
    "StockValuationByLocReport": StockValuationByLocReport,
    "StockValuationReport": StockValuationReport,
    "FundTransferList": FundTransferList,
    "OnlineLearnersList": OnlineLearnersList,
    "SupplierBalanceReport": SupplierBalanceReport,
    "CustomerBalanceReport": CustomerBalanceReport,
    "LmsNotificationList": LmsNotificationList,
    "MyLmsNotificationList": MyLmsNotificationList,
    "LoadingListReport": LoadingListReport,
    "IngredientList": IngredientList,
    "FoodList": FoodList,
    "SalesByCustomerSummary": SalesByCustomerSummary,
    "SalesByCustomerDetail": SalesByCustomerDetail,
    "SalesByItemSummary": SalesByItemSummary,
    "CRVSummary": CRVSummary,
    "CRVDetail": CRVDetail,
    "CustomerBalanceSummary": CustomerBalanceSummary,
    "CustomerAgingSummary": CustomerAgingSummary,
    "SalesByCustomerType": SalesByCustomerType,
    "SalesOrderQuantity": SalesOrderQuantity,
    "SalesOrderValuation": SalesOrderValuation,
    "SalesByItemDetail": SalesByItemDetail,
    "BillsBySupplierSummary": BillsBySupplierSummary,
    "BillsBySupplierDetail": BillsBySupplierDetail,
    "BillsByItemSummary": BillsByItemSummary,
    "BillsByItemDetail": BillsByItemDetail,
    "SupplierBalanceSummary": SupplierBalanceSummary,
    "SupplierBalanceDetail": SupplierBalanceDetail,
    "SupplierAgingSummary": SupplierAgingSummary,
    "StockByLocation": StockByLocation,
    "InvValuationSummary": InvValuationSummary,
    "InvValuationDetail": InvValuationDetail,
    "StockStatusByItem": StockStatusByItem,
    "ConsumptionReport": ConsumptionReport,
    "ConsumptionByLocation": ConsumptionByLocation,
    "BalanceSheet": BalanceSheet,
    "ProfitLoss": ProfitLoss,
    "TrialBalance": TrialBalance,
    "VATSummary": VATSummary,
    "VATDetail": VATDetail,
    "AccountTree": AccountTree,
    "CustomerBalanceDetail": CustomerBalanceDetail,
    "V2SalesList": V2SalesList,
    "CustomerList": CustomerList,
    "VendorList": VendorList,
    "EmployeeList": EmployeeList,
    "CustomerTypeList": CustomerTypeList,
    "InventoryLocationList": InventoryLocationList,
    "V2SalesReport": V2SalesReport,
    "CostingAudit": CostingAudit,
    "V2StockReport": V2StockReport,
    "PhysicalInventoryWorksheet": PhysicalInventoryWorksheet,
    "V2BillList": V2BillList,
    "StockAdjustmentList": StockAdjustmentList,
    "StockTransferList": StockTransferList,
    "StockWasteList": StockWasteList,
    "SalesByCustomerDetailPerItem": SalesByCustomerDetailPerItem,
    "BillsBySupplierPerItem": BillsBySupplierPerItem,
    "V2BillsReport": V2BillsReport,
    "PackingList": PackingList,
    "PurchaseOrderList": PurchaseOrderList,
    "CRVAccountsBalance": CRVAccountsBalance,
    "ConsumptionByService": ConsumptionByService,
    "SalesReportDetail": SalesReportDetail,
    "SalesConsumptionDetail": SalesConsumptionDetail,
    "V2LedgerReport": V2LedgerReport,
    "RestOrderList": RestOrderList,
    "SalesBySupplier": SalesBySupplier,
    "SalesByItem": SalesByItem,
    "SerialNoReport": SerialNoReport,
    "PaymentMethodReport": PaymentMethodReport,
    "BookClosings": BookClosings,
    "PaymentMethodDetail": PaymentMethodDetail,
    "SalesOrderReport": SalesOrderReport,
    "CustomerOutstandingReport": CustomerOutstandingReport,
    "SupplierOutstandingReport": SupplierOutstandingReport,
    'FnbEventList': FnbEventList,
    'Productions': Productions,
    'SalesOrderConsumption': SalesOrderConsumption,
    'CogsTrailReport': CogsTrailReport,
    "InvRequests": InvRequests,
    "ActiveInvRequests": ActiveInvRequests,
    "AllInvRequests": AllInvRequests,
    "V1PoList": V1PoList,
    "CourierReceipt": CourierReceipt,
    "CargoStockReport": CargoStockReport,
    "CargoLocationReport": CargoLocationReport,
    "CargoReceiptNoteList": CargoReceiptNoteList,
    "CargoIssueForTransferList": CargoIssueForTransferList,
    "CargoReceiptFromTransferList": CargoReceiptFromTransferList,
    "CargoInventory": CargoInventory,
    "ItemConditionNamesList": ItemConditionNamesList,
    "InvAdjustmentList": InvAdjustmentList,
    "LastStockAdjustReport": LastStockAdjustReport,
    "V1CustomerList": V1CustomerList,
}

export function getReport(reportName) {
    return REPORTS[reportName]
}