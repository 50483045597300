import './styles.scss';
import { clearAccount, getAccount } from "../session/SessionManager";
import RoboBlack from '../images/RoboBlack.png'
import picardlogo from '../images/other/picard/picardlogo.png'
import { withLoadablePage } from '../base/Page';
import Api from '../session/Api';
import Button from '../components/Button';
import UIUtil from '../util/UIUtil';
import Util from '../util/Util';
import { OBJECT_TYPE_LEAD } from '../constants/ObjectTypes';
import { CommentView } from '../views/comment/comment-view';
import { Tab, Tabs, Tag, TextInput } from 'carbon-components-react';
import { Logout16, ArrowRight16, Pdf16, ArrowLeft16, Attachment16, Table16 } from '@carbon/icons-react'
import { openPdf } from '../markup-template-engine';
import { useEffect, useMemo, useState } from 'react';
import { SidePanel } from '../templates/draft/components/side-panel';
import { AdvancedNotesView } from '../views/advanced-notes/advanced-notes-view';
import { asyncPrintStatementOfAccount } from '../pdfs/statements-of-account/StatementsOfAccountPdf';
import { InvItemContent } from './inv-item-content';
import { loadAppData } from '../app/data';

async function logout() {
    const stopLoading = await UIUtil.pageLoader();
    try {
        await Api.asyncLogout();
        clearAccount();

        window.location.href = '/'
    } catch (e) {
        stopLoading();
    }
}

async function viewStatement() {
    const stopLoading = await UIUtil.pageLoader();
    try {
        await asyncPrintStatementOfAccount(-1, -1, 0, new Date().getTime());
    } finally {
        stopLoading();
    }
}


function onInvExportBtn(inventory) {
    const headers = ['CRN No', 'Item ID', 'Code', 'Description', 'Supplier', 'HS Code', 'CoA', 'Width (M)', 'Height (M)', 'Length (M)', 'Weight (KG)', 'Barcodes'];
    const rows = inventory.map(item => [
        Util.getVoucherNumber(item.crnId),
        item.itemId,
        item.code,
        item.description,
        item.supplier,
        item.hsCode,
        item.origin,
        item.width,
        item.height,
        item.length,
        item.weight,
        item.barcodes?.map(barcode => barcode.value + ' (' + barcode.type + ')').join(' - ')
    ])


    let csvContent = "data:text/csv;charset=utf-8,";

    [headers, ...rows].forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);

    const a = document.createElement("a");
    a.href = encodedUri;
    a.download = "Inventory.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}


function View({ payload: data }) {
    const [conversationId, setConversationId] = useState(null);
    const [advancedNoteId, setAdvancedNoteId] = useState(null);
    const [invItem, setInvItem] = useState(null);
    const [invItemSearch, setInvItemSearch] = useState('');

    useEffect(() => {
        loadAppData();
    }, [])

    const inventory = useMemo(() => {
        if (!invItemSearch) {
            return data.invItems;
        }

        return data.invItems.filter(item => {
            const compare = (a, b) => (a?.toString?.() || "").toLowerCase().includes((b?.toString?.() || "").toLowerCase());

            const conditions = [
                compare(item.code, invItemSearch),
                compare(item.description, invItemSearch),
                compare(item.origin, invItemSearch),
                compare(item.supplier, invItemSearch),
                compare(item.hsCode, invItemSearch),
                compare(Util.getVoucherNumber(item.crnId), invItemSearch),
                ...(item.barcodes?.map(barcode => compare(barcode.value, invItemSearch)) ?? [])
            ]

            return conditions.some($ => $);
        })
    }, [data.invItems, invItemSearch]);

    return (
        <div className='cargo-customer' style={{ width: '100%', height: '100dvh' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img className='logo' src={picardlogo} alt="Logo" style={{ objectFit: 'contain', objectPosition: 'center', width: 200 }} />
                <div style={{ flex: 1 }} />
                <Button renderIcon={Logout16} size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={logout}>Logout</Button>
            </div>
            <h1 className='cargo-c-title'>Hello, {getAccount().fullName}!</h1>
            <h2 className='cargo-c-subtitle'>Welcome to the Customer Portal</h2>


            <div className="short-width-tabs-dyn">
                <Tabs>
                    <Tab label="Account">
                        <h3 className='cargo-c-balance'>Balance: AED {Util.formatMoney(data.balance)}</h3>
                        <Button renderIcon={ArrowRight16} size="sm" style={{ borderRadius: 5, marginBottom: '1rem' }} kind="secondary" onClick={viewStatement}>View Statement</Button>
                    </Tab>
                    <Tab label="Current Inventory">
                        <div style={{ display: 'flex', gap: 5 }}>
                            <TextInput style={{ fontSize: 16, borderRadius: 5 }} placeholder='Quick search...' value={invItemSearch} onChange={e => setInvItemSearch(e.target.value)} />
                            <Button renderIcon={Table16} size="sm" style={{ borderRadius: 5 }} kind="secondary" onClick={() => onInvExportBtn(inventory)}>Export</Button>
                        </div>
                        <div style={{ height: '1rem' }} />
                        {inventory.map(item => <>
                            <div key={item.id} className='cargo-inv-item'>
                                <Tag type="high-contrast">CRN: {Util.getVoucherNumber(item.crnId)}</Tag>
                                <p className='cargo-inv-item-code'>{item.code}</p>
                                <p>{item.description}</p>
                                <div className='cargo-inv-item-details' style={{ display: 'flex', alignItems: 'center' }}>
                                    <p>Width (M): {item.width}</p>
                                    <p>Height (M): {item.height}</p>
                                    <p>Length (M): {item.length}</p>
                                    <p>Weight (KG): {item.weight}</p>
                                </div>
                                <Button renderIcon={ArrowRight16} size="sm" style={{ display: 'block', borderRadius: 5, marginTop: '1rem', marginLeft: 'auto' }} kind="secondary" onClick={() => setInvItem(item)}>
                                    View Details
                                </Button>
                            </div>
                        </>)}
                    </Tab>
                    <Tab label="Conversations">
                        {data.conversations.map(item => <>
                            <div key={item.id} className='cargo-inv-item' style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    <p>Request #{item.id}</p>
                                    {!!item.crnId && <Tag type="high-contrast">CRN: {Util.getVoucherNumber(item.crnId)}</Tag>}
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>Conversation</p>
                                </div>
                                <Button renderIcon={ArrowRight16} hasIconOnly iconDescription="PDF" size="md" style={{ borderRadius: 5, }} onClick={() => {
                                    setConversationId(item);
                                }} />
                            </div>
                        </>)}
                    </Tab>
                    <Tab label="CDOs">
                        {data.cdos.map(item => <>
                            <div key={item.id} className='cargo-inv-item' style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    <p>{item.orderNo}</p>
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>Cargo Delivery Order</p>
                                </div>
                                <Button renderIcon={Pdf16} hasIconOnly iconDescription="PDF" size="md" style={{ borderRadius: 5, }} kind="secondary" onClick={() => openPdf('CargoDeliveryOrderDoc', item.id)} />
                            </div>
                        </>)}
                    </Tab>
                </Tabs>
            </div>
            {/* invItems */}


            {!!conversationId && <SidePanel fullscreen md2 onClose={() => setConversationId(null)}>
                <div style={{ height: '3rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem' }}>
                    <Button size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={() => setConversationId(null)}>
                        <ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back
                    </Button>
                    <h3 style={{ fontWeight: 'bold', flex: 1, fontSize: 16 }}>Conversation {!!conversationId.crnId && <Tag type="cool-gray">CRN: {conversationId.crnId}</Tag>}</h3>
                    <Button renderIcon={Attachment16} size="sm" style={{ borderRadius: 5 }} kind="secondary" onClick={() => setAdvancedNoteId(conversationId.id)}>
                        Attachments
                    </Button>
                </div>
                <div style={{ height: 'calc(100dvh - 3rem)' }}>
                    <CommentView itemId={conversationId.id} itemType={OBJECT_TYPE_LEAD} noGapInSendBox />
                </div>
            </SidePanel>}

            {!!advancedNoteId && <SidePanel fullscreen md2 onClose={() => setAdvancedNoteId(0)}>
                <div style={{ height: '3rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem' }}>
                    <Button size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={() => setAdvancedNoteId(0)}>
                        <ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back
                    </Button>
                    <h3 style={{ fontWeight: 'bold', flex: 1, fontSize: 16 }}>Attachments</h3>
                </div>
                <div style={{}}>
                    <AdvancedNotesView objectId={advancedNoteId} objectType={OBJECT_TYPE_LEAD} title="" />
                </div>
            </SidePanel>}

            {!!invItem && <SidePanel fullscreen md2 onClose={() => setInvItem(null)}>
                <div style={{ height: '3rem', display: 'flex', alignItems: 'center', paddingInline: '1rem', gap: '0.25rem', borderBottom: '1px solid #00000020' }}>
                    <Button size="sm" style={{ borderRadius: 5 }} kind="ghost" onClick={() => setInvItem(null)}>
                        <ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back
                    </Button>
                    <h3 style={{ fontWeight: 'bold', flex: 1, fontSize: 16 }}>Item</h3>
                </div>
                <InvItemContent data={invItem} />
            </SidePanel>}
        </div>
    )

}

export const CargoCustomerApp = withLoadablePage(listener => Api.cargoCustomer.getIndex(listener), View);