import { TextInput } from "carbon-components-react";
import Portal from "../util/Portal";
import { CustomerSearchDialog } from "../pages/pos/dialogs/customer-search-dialog";
import { useEffect, useState } from "react";
import Api from "../session/Api";
import Button from "./Button";
import { Close16 } from '@carbon/icons-react'


export function CustomDialogSelectField({ light, value, onChange, onVisibilityHandler }) {
    const [searchVisible, setSearchVisible] = useState(false);
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        if (!value) {
            setCustomer(null);
            return;
        }

        let cancelled = false;

        setCustomer({ id: value, fullName: 'Loading...' });

        Api.getMiniCustomer(value, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setCustomer(response.payload);
            } else {
                setCustomer({ id: value, fullName: 'Customer preview disabled' });
            }
        })

    }, [value]);

    useEffect(() => {
        if (onVisibilityHandler) {
            onVisibilityHandler(searchVisible);
        }
    }, [searchVisible]);

    return <>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextInput style={{ height: 40 }} light={light} labelText="Search Customer" placeholder="Email or number"
                value={customer ? customer.fullName : ''} onChange={e => { }} onClick={() => setSearchVisible(true)} />
            {!!value && <Button kind="danger" style={{ height: 40 }} size="md" onClick={() => onChange(0)} hasIconOnly iconDescription="Clear">
                <Close16 />
            </Button>}
        </div>

        <Portal>
            <CustomerSearchDialog visible={searchVisible} onClose={() => setSearchVisible(false)} onCustomerSelected={customer => onChange(customer?.id || 0)} />
        </Portal>
    </>
}