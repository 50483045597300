import { InlineLoading, Link, Tab, Tabs } from 'carbon-components-react';
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CONTACT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_LOYALTY_CARD_ISSUE, OBJECT_TYPE_STORE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TERMINAL } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import { getAccountRole } from '../../session/SessionManager';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import PriceOverrideList from '../../views/price-override/PriceOverrideList';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView';

import { ArrowRight16, Analytics16, Currency16, Calendar16, Calendar24, Currency24, Download32, Upload32, Pdf24, VirtualColumn32 } from '@carbon/icons-react'
import AllSubsidiaryReportView, { AllSubsidiaryReportViewReportEngineSubsidiaryReport } from '../accounting-reports/AllSubsidiaryReportView';
import { BalanceView, formatBalanceAmount } from '../../views/accounting/BalanceView';
import { hasCapabilitySupport } from '../../app/Capabilities';
import DeliverablesListView from '../sales-order/DeliverablesListView';
import { ReportPage } from '../../reporting-engine/base/report-page';
import AllOrdersListView from '../sales-order/AllOrdersListView';
import { CustomerAddress } from '../../views/customer/customer-address';
import { printStatementOfAccount, printStatementOfAccountWithCurrency, asyncPrintStatementOfAccount } from '../../pdfs/statements-of-account/StatementsOfAccountPdf';
import UIUtil from '../../util/UIUtil';
import { Link as LK } from "react-router-dom"
import { isAdidas } from '../../app/app-util';
import { toBase64 } from '../../util/Util';

const AddressList = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <CustomerAddress customerId={item.id} />
        </div>
    )
}

const ContactsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'customerId',
                    parentId: item.id,
                    showNotIncluded: false
                },
                embedded: true,
                title: "Contacts",
                subTitle: "Customer contacts",
                objectType: OBJECT_TYPE_CONTACT,
                pagePath: "/contacts/",
                history: history
            })}
        </div>
    )
}

const SalesOrders = ({ item }) => {
    if (hasCapabilitySupport("restaurant")) {
        return (
            <div style={{ marginTop: '1rem' }}>
                <AllOrdersListView customerId={item.id} />
            </div>
        )
    }

    return (
        <div style={{ marginTop: '1rem' }}>
            <DeliverablesListView customerId={item.id} />
        </div>
    )
}

const PriceOverride = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <PriceOverrideList parent={item} />
        </div>
    )
}

const IssuesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'customerId',
                    parentId: item.id,
                    showNotIncluded: false,
                    nonEditable: true
                },
                embedded: true,
                title: "Loyalty Cards",
                subTitle: "Customer loyalty cards",
                objectType: OBJECT_TYPE_LOYALTY_CARD_ISSUE,
                pagePath: "/loyalty-cards-issue/",
                history: history
            })}
        </div>
    )
}

const ReportEngineSubsidiaryReport = ({ item }) => {
    const query = useMemo(() => toBase64(JSON.stringify({
        selectedSubsidiaryAccountLedger: { id: item.id, name: item.fullName },
        filters: [
            { id: "subsidiaryId", property: "subsidiaryId", operator: "EQ", value: item.id },
        ]
    })), [item])
    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ height: '100vh', width: '100%' }}>
                <ReportPage reportName="LedgerReport" overrideQuery={query} componentMode />
            </div>
        </div>
    )
}

const SubsidiaryReport = ({ item }) => {
    return <AllSubsidiaryReportViewReportEngineSubsidiaryReport item={item} />

    if (isAdidas()) {
        return <ReportEngineSubsidiaryReport item={item} />
    }

    return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE_CUSTOMER} />
}

const SubsidiaryList = ({ item }) => {
    return <AllSubsidiaryReportView itemId={item.id} itemType={OBJECT_TYPE_CUSTOMER} />
}

const StocksView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <StockFlowListView locationList={{ id: item.id, type: OBJECT_TYPE_CUSTOMER }} />
        </div>
    )
}

const TransactionsView = ({ item }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <TransactionListView partyList={{ id: item.id, type: OBJECT_TYPE_CUSTOMER }} />
        </div>
    )
}

const Dummy = () => {
    return <div />
}

const LeadFlows = ({ item }) => {
    const query = useMemo(() => toBase64(JSON.stringify({
        filters: [
            { id: "customerId", property: "customerId", operator: "EQ", value: item.id }
        ]
    })), [item])
    return (
        <div style={{ height: 'calc(100vh - 15rem)' }}>
            <ReportPage reportName="LeadsReport" embeddedMode overrideQuery={query} />
        </div>
    )
}

const Shipments = ({ item }) => {
    const query = useMemo(() => toBase64(JSON.stringify({
        filters: [
            { id: "invoicedCustomerId", property: "invoicedCustomerId", operator: "EQ", value: item.id }
        ]
    })), [item])
    return (
        <div style={{ height: 'calc(100vh - 15rem)' }}>
            <ReportPage reportName="ShipmentList" embeddedMode overrideQuery={query} />
        </div>
    )
}

const PrintStatementBtn = ({ item }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true)
        printStatementOfAccount(
            item.id, OBJECT_TYPE_CUSTOMER,
            0, new Date().getTime(),
            () => setLoading(false)
        )
    }
    const onCurrencyClick = async () => {
        setLoading(true);
        try {
            const currencies = await Api.asyncGetCurrencies()
            const currency = await UIUtil.listPrompt("Select Currency", currencies);
            if (!currency) {
                return;
            }

            await printStatementOfAccountWithCurrency(currency, item.id, OBJECT_TYPE_CUSTOMER, 0, new Date().getTime());
        } finally {
            setLoading(false);
        }
    }
    const onPeriodClick = async () => {
        try {
            const currenciesPromise = Api.asyncGetCurrencies();
            const range = await UIUtil.dateInputPrompt({ title: "Statement Period" });
            if (!range) {
                return;
            }

            const currencies = [
                { id: 0, value: 'Default - AED' },
                ...await currenciesPromise
            ]
            const currency = await UIUtil.listPrompt("Select Currency", currencies);
            if (!currency) {
                return;
            }

            if (currency.id) {
                await printStatementOfAccountWithCurrency(currency, item.id, OBJECT_TYPE_CUSTOMER, range.start, range.end);
            } else {
                await asyncPrintStatementOfAccount(item.id, OBJECT_TYPE_CUSTOMER, range.start, range.end)
            }
        } finally {
            setLoading(false);
        }
    }
    return (<>
        {hasCapabilitySupport("shipping") ? (<>
            <Button kind="secondary" renderIcon={Calendar16} loading={loading} onClick={onPeriodClick}>Period Statement</Button>
            <Button className="green-button" renderIcon={Analytics16} loading={loading} onClick={onClick}>AED Statement</Button>
            <Button renderIcon={Currency16} loading={loading} onClick={onCurrencyClick}>Currency Statement</Button>
        </>) : (<>
            <Button kind="secondary" renderIcon={Calendar16} loading={loading} onClick={onPeriodClick}>Period Statement</Button>
            <Button className="green-button" renderIcon={Analytics16} loading={loading} onClick={onClick}>Statement</Button>
        </>)}
    </>)
}


const SharedBalance = ({ item }) => {
    const [loading, setLoading] = useState(false);

    const printStatement = async (id, type, withCurrency, withDate) => {
        setLoading(true)

        try {
            if (withDate) {
                const currenciesPromise = Api.asyncGetCurrencies();
                const range = await UIUtil.dateInputPrompt({ title: "Statement Period" });
                if (!range) {
                    return;
                }

                const currencies = [
                    { id: 0, value: 'Default - AED' },
                    ...await currenciesPromise
                ]
                const currency = await UIUtil.listPrompt("Select Currency", currencies);
                if (!currency) {
                    return;
                }

                if (currency.id) {
                    await printStatementOfAccountWithCurrency(currency, id, type, range.start, range.end);
                } else {
                    await asyncPrintStatementOfAccount(id, type, range.start, range.end)
                }
            } else if (withCurrency) {
                const currencies = await Api.asyncGetCurrencies()
                const currency = await UIUtil.listPrompt("Select Currency", currencies);
                if (!currency) {
                    return;
                }

                await printStatementOfAccountWithCurrency(currency, id, type, 0, new Date().getTime());
            } else {
                await asyncPrintStatementOfAccount(id, type, 0, new Date().getTime())
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            <div style={{
                padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem',
                borderRadius: 7, background: "#00990020", border: '1px solid #00990040', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) '
            }}>
                <Download32 style={{ color: 'green' }} />
                <div style={{ marginRight: '1rem' }}>
                    <p style={{ fontWeight: 'bold', color: 'green', fontSize: 14 }}>Receivable</p>
                    <h4>{formatBalanceAmount(item.balance, "Cr ", "Dr ")}</h4>
                </div>
                <Button onClick={() => printStatement(item.id, OBJECT_TYPE_CUSTOMER, false, true)} disabled={loading} kind="secondary" style={{ borderRadius: 5 }} renderIcon={Calendar24} hasIconOnly iconDescription="Period Statement" />
                <Button onClick={() => printStatement(item.id, OBJECT_TYPE_CUSTOMER, true, false)} disabled={loading} kind="secondary" style={{ borderRadius: 5 }} renderIcon={Currency24} hasIconOnly iconDescription="Currency Statement" />
                <Button onClick={() => printStatement(item.id, OBJECT_TYPE_CUSTOMER, false, false)} disabled={loading} style={{ borderRadius: 5 }} renderIcon={Pdf24} hasIconOnly iconDescription="AED Statement" />
            </div>
            <div style={{
                padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem',
                borderRadius: 7, background: "#99000020", border: '1px solid #99000040', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) '
            }}>
                <Upload32 style={{ color: 'red' }} />
                <div style={{ marginRight: '1rem' }}>
                    <p style={{ fontWeight: 'bold', color: 'red', fontSize: 14 }}>Payable</p>
                    <h4>{formatBalanceAmount(item.connectedSupplierBalance, "Dr ", "Cr ")}</h4>
                </div>
                <Button onClick={() => printStatement(item.connectedSupplierId, OBJECT_TYPE_SUPPLIER, false, true)} disabled={loading} kind="secondary" style={{ borderRadius: 5 }} renderIcon={Calendar24} hasIconOnly iconDescription="Period Statement" />
                <Button onClick={() => printStatement(item.connectedSupplierId, OBJECT_TYPE_SUPPLIER, true, false)} disabled={loading} kind="secondary" style={{ borderRadius: 5 }} renderIcon={Currency24} hasIconOnly iconDescription="Currency Statement" />
                <Button onClick={() => printStatement(item.connectedSupplierId, OBJECT_TYPE_SUPPLIER, false, false)} disabled={loading} style={{ borderRadius: 5 }} renderIcon={Pdf24} hasIconOnly iconDescription="AED Statement" />
            </div>

            {hasCapabilitySupport("shipping") && <div style={{
                padding: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem',
                borderRadius: 7, background: "#00009920", border: '1px solid #00009940', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) '
            }}>
                <VirtualColumn32 style={{ color: 'blue' }} />
                <div style={{ marginRight: '1rem' }}>
                    <p style={{ fontWeight: 'bold', color: 'blue', fontSize: 14 }}>Statement</p>
                    <h4>{formatBalanceAmount(item.balance - item.connectedSupplierBalance, "Cr ", "Dr ")}</h4>
                </div>
                <Button onClick={() => printStatement(item.id, -OBJECT_TYPE_CUSTOMER, false, true)} disabled={loading} kind="secondary" style={{ borderRadius: 5 }} renderIcon={Calendar24} hasIconOnly iconDescription="Period Statement" />
                <Button onClick={() => printStatement(item.id, -OBJECT_TYPE_CUSTOMER, true, false)} disabled={loading} kind="secondary" style={{ borderRadius: 5 }} renderIcon={Currency24} hasIconOnly iconDescription="Currency Statement" />
                <Button onClick={() => printStatement(item.id, -OBJECT_TYPE_CUSTOMER, false, false)} disabled={loading} style={{ borderRadius: 5 }} renderIcon={Pdf24} hasIconOnly iconDescription="AED Statement" />
            </div>}
        </div>
    )
}


class CustomerDetailPage extends Page {

    accountRole = getAccountRole();
    hasOrderManagement = (this.accountRole == ACCOUNT_TYPE_ADMINISTRATION || this.accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("orderManagementSystem")

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined,
            tab: 0
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_CUSTOMER, listener)
            } else {
                Api.getItem(OBJECT_TYPE_CUSTOMER, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {

        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    overrideBacktToListBtn: this.props.overrideBacktToListBtn,
                    objectType: OBJECT_TYPE_CUSTOMER,
                    itemResult: this.state.itemResult,
                    onSave: this.props.onSave,
                    pagePath: "/customers/",
                    history: this.props.history,
                    onTabChanged: tab => this.setState({ tab }),
                    titleBottom: <>
                        {this.state.itemResult.item.connectedSupplierId > 0 ? <>
                            <LK to={"/suppliers/" + this.state.itemResult.item.connectedSupplierId}>
                                <Link style={{ marginTop: '0.5rem' }} renderIcon={ArrowRight16}>View Supplier</Link>
                            </LK>
                        </> : null}
                    </>,
                    hasCustomBtn: <>
                        {this.state.itemResult.item.connectedSupplierId > 0 ? (<>
                            <SharedBalance item={this.state.itemResult.item} />
                        </>) : (<>
                            <div style={{ width: '1rem' }} />
                            <BalanceView item={this.state.itemResult.item} subsidiaryType={OBJECT_TYPE_CUSTOMER} posPrefix={"Dr "} negPrefix={"Cr "} />
                            <div style={{ width: '1rem' }} />
                            <PrintStatementBtn item={this.state.itemResult.item} />
                        </>)}
                    </>,
                    customTabs: [
                        ...(hasCapabilitySupport("shipping") ? [{
                            title: "Shipments",
                            component: this.state.tab === 1 ? Shipments : Dummy
                        }] : []),
                        ...(hasCapabilitySupport("onlineStore") ? [{
                            title: "Addresses",
                            component: AddressList
                        }] : []),
                        ...(hasCapabilitySupport("lead") ? [{
                            title: "Lead Flows",
                            component: this.state.tab === (hasCapabilitySupport("shipping") ? 3 : hasCapabilitySupport("onlineStore") ? 2 : 1) ? LeadFlows : Dummy
                        }] : []),
                        {
                            title: "Contacts",
                            component: ContactsList
                        },
                        ...(this.hasOrderManagement ? [{
                            title: "Sales Orders",
                            component: SalesOrders
                        }] : []),
                        ...(getAccountRole() != ACCOUNT_TYPE_SALES ? [{
                            title: "Loyalty Cards",
                            component: IssuesList
                        },
                        {
                            title: "Price Override",
                            component: PriceOverride
                        },
                        {
                            title: "Ledger Report",
                            component: SubsidiaryReport
                        },
                        ...(isAdidas() ? [{
                            title: "Ledger List",
                            component: SubsidiaryList
                        }] : []),
                        {
                            title: "Stock",
                            component: StocksView
                        },
                        {
                            title: "Transactions",
                            component: TransactionsView
                        }] : []),
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(CustomerDetailPage);