import React from 'react'
import Page from '../../base/Page';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Delivery32,
    Building32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Currency16,
    Reset16,
    FlowStream16,
    Launch32,
    User32,
    ShoppingCartArrowUp32 as SaleIcon,
    ShoppingCartArrowDown32 as PurchaseIcon,
    CheckmarkOutline16,
    CheckmarkFilled16,
    ErrorFilled16,
    Enterprise32,
    Document32,
    ChartBullet32,
    RowDelete32,
    Education32,
    UserSpeaker32,
    BuildingInsights_332,
    ArrowLeft16
} from '@carbon/icons-react'
import { TileGroup, RadioTile, Link as DLink, TextArea, ComboBox, ButtonSet, Switch, ContentSwitcher, NumberInput, ComposedModal, ModalHeader, ModalBody, ModalFooter, InlineLoading, DatePicker, DatePickerInput, TextInput, Toggle } from 'carbon-components-react';
import { ACCOUNT_TYPE_CASHIER, ACCOUNT_TYPE_SUPERVISOR, CHEQUE_OTHER_PARTY_TYPE_CUSTOMER, CHEQUE_OTHER_PARTY_TYPE_OTHER, CHEQUE_OTHER_PARTY_TYPE_STAFF, CHEQUE_OTHER_PARTY_TYPE_SUPPLIER, CHEQUE_OTHER_PARTY_TYPE_VENDOR, CHEQUE_TYPE_RECEIVABLE, JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT, JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT, LEDGER_TYPE_ASSET, LEDGER_TYPE_EXPENSE, LEDGER_TYPE_INCOME, PAYMENT_METHOD_CASH, PAYMENT_METHOD_CHEQUE, TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_DIRECTION_TYPE_OUTWARD, TRANSACTION_MODE, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR, TRANSACTION_PARTY_TYPE_OTHER, TRANSACTION_PARTY_TYPE_STAFF, TRANSACTION_PARTY_TYPE_STORE, TRANSACTION_PARTY_TYPE_STUDENT, TRANSACTION_PARTY_TYPE_SUPPLIER, TRANSACTION_PARTY_TYPE_TUTOR, TRANSACTION_PARTY_TYPE_V2_CUSTOMER, TRANSACTION_PARTY_TYPE_V2_EMPLOYEE, TRANSACTION_PARTY_TYPE_V2_VENDOR, TRANSACTION_PARTY_TYPE_VENDOR, TRANSACTION_PARTY_TYPE_VENUE } from '../../constants/Constants';
import TransactionEditor from './TransactionEditor';
import Button from '../../components/Button';
import Util, { flattenItems, flattenItems2, isV2 } from '../../util/Util';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { withRouter } from 'react-router-dom';
import CustomComboBox from '../../components/CustomComboBox';
import { Link } from 'react-router-dom';
import TransactionAgainstSelectionDialog from './TransactionAgainstSelectionDialog';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { AGAINST_MODE } from './transactions';
import { getCashGroup, getPayableGroup, getReceivableGroup } from '../../domain/account-ledgers';
import { createAgainstOptions } from '../../domain/transaction-against';
import { AmountTagSelectorNormalField } from '../journal-entries/tag-selector';
import { getAccountRole } from '../../session/SessionManager';
import JournalEntryEditor from '../journal-entries/JournalEntryEditor';
import { CustomDialogSelectField } from '../../components/customer-dialog-select-field';

const Section = ({ children, icon, title, options, extraTopMargin, noMargin }) => (
    <div style={{ marginTop: noMargin ? 0 : extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: 1 }}>{title}</p>
            {options}
        </div>
        {children}
    </div>
)

// const RadioItem = ({ icon, title, desc }) => (
//     <div style={{ display: 'flex', flexDirection: 'column' }}>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//             {React.createElement(icon)}
//             <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
//         </div>
//         <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
//             {desc}
//         </p>
//     </div>
// )

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginTop: '0.5rem', textAlign: 'center' }}>{title}</h4>
        <p style={{ marginTop: '0rem', textAlign: 'center', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

//let link = againstItem.invoiceMode ? '/invoice-documents/' + againstItem.stockFlow.id : "/stock-flow/" + againstItem.stockFlow.id
// const date = againstItem.invoiceMode ? Util.getDate(againstItem.stockFlow.dateCreated) : Util.getDate(againstItem.stockFlow.initiationDate);
// if (againstItem.invoiceMode) {
//     stockFlowType = "Invoice Document";
// }

// if (againstItem.billMode) {
//     link = '/bill/' + againstItem.stockFlow.id;
//     stockFlowType = "Bill"
// }

const AgainstItem = ({ againstItem, onRemove }) => {
    const willBeFullyPaid = againstItem.dividedAmount >= (againstItem.targetItem.amount - againstItem.targetItem.amountPaid);
    let amountLeft;
    if (!willBeFullyPaid) {
        amountLeft = (againstItem.targetItem.amount - againstItem.targetItem.amountPaid) - againstItem.dividedAmount;
    }


    const name = againstItem.name;
    const link = againstItem.targetAgainstOption.getItemLink(againstItem.targetItem)
    const date = Util.getDate(againstItem.targetAgainstOption.getItemDate(againstItem.targetItem));
    return (
        <div style={{
            transition: '250ms', overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'flex-start', padding: '15px' }}>
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    {/* <Link to={"/stock-flow/" + againstItem.stockFlowId} target="_blank"> */}
                    <Link to={link} target="_blank">
                        <p style={{ fontSize: 14 }}>{name} (voucher no: {Util.getVoucherNumber(againstItem.targetItem.id)})</p>
                    </Link>
                    <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem', marginBottom: '0rem' }}>{date}</p>

                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '1rem' }}>
                        <h2>AED {againstItem.dividedAmount.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}>(Transaction Amount)</p>
                    </div>
                    <p style={{ paddingBottom: '0.25rem' }}>AED {againstItem.targetItem.amountPaid.toFixed(2)} (Amount Paid Already)</p>
                    <p style={{ paddingBottom: '0.25rem' }}>AED {againstItem.targetItem.amount.toFixed(2)} (Total {name} Amount)</p>


                    {willBeFullyPaid ? (
                        <p style={{ color: 'green', fontSize: 14 }}>{name} will be fully paid</p>
                    ) : (
                        <p style={{ color: 'red', fontSize: 14, }}>{name} will be partially paid (AED {amountLeft.toFixed(2)} left)</p>
                    )}
                </div>
                <Button onClick={onRemove} kind={"danger"} renderIcon={ErrorFilled16} style={{ width: 195 }}>Remove</Button>
            </div>
        </div>
    )
}

class TransactionCreatorPage extends Page {

    getEditState() {
        if (!this.editMode) {
            return {}
        }

        const transaction = this.editMode;
        return {
            directionTypeValue: transaction.directionType,
            modeValue: transaction.mode,

            otherPartyTypeValue: transaction.otherPartyType,
            otherPartyIdValue: transaction.otherPartyId,

            destinationTypeValue: transaction.destinationType,
            destinationIdValue: transaction.destinationId,

            // againstItemsValue: transaction.againstItems,

            amountValue: transaction.amount,
            paymentMethodsValue: transaction.paymentMethods,
            infoValue: transaction.info,
            refNoValue: transaction.refNo,
            transactionDateValue: transaction.transactionDate,

            // cashAccount?.id: transaction.requestedCashAccountId,
            // againstOfAccount?.id: transaction.requestedAgainstOfAccountId,
            tagsValue: transaction.tags.map($ => ({ ...$, label: $.name })),

            approvedByName: transaction.approvedByName,
            receivedByName: transaction.receivedByName,
            approvedByIdNo: transaction.approvedByIdNo,
            receivedByIdNo: transaction.receivedByIdNo,
        }
    }

    constructor(props) {
        super(props);

        this.editMode = props.editing

        this.state = {
            ...this.state,

            directionTypeValue: this.isVoucher() ? (this.props.receiptVoucher ? TRANSACTION_DIRECTION_TYPE_INWARD : TRANSACTION_DIRECTION_TYPE_OUTWARD) : TRANSACTION_DIRECTION_TYPE_INWARD,
            modeValue: TRANSACTION_MODE.METHOD,
            otherPartyTypeValue: (
                isV2() ? (
                    TRANSACTION_PARTY_TYPE_V2_CUSTOMER
                ) : (
                    (this.props.paymentVoucher || this.props.courier) ? TRANSACTION_PARTY_TYPE_SUPPLIER + "" : TRANSACTION_PARTY_TYPE_CUSTOMER
                )
            ),
            otherPartyIdValue: 0,

            otherPartyIdObject: undefined,

            cashAccount: undefined,
            againstOfAccount: undefined,
            amountValue: 0,
            jrAmountsValue: [],
            paymentMethodsValue: [],
            infoValue: "",

            againstItemsValue: [],

            refNoValue: "",
            transactionDateValue: new Date().getTime(),

            approvedByName: "",
            receivedByName: "",
            approvedByIdNo: "",
            receivedByIdNo: "",

            tagsValue: [],

            otherPartyIdPickerKey: Util.newTempId(),
            paymentMethodPickerKey: Util.newTempId(),
            journalEditorKey: Util.newTempId(),

            suppliers: [],
            customers: [],
            staff: [],
            accountTree: [],
            vendors: [],
            stores: [],
            venues: [],
            students: [],
            tutors: [],
            movieDistributors: [],
            predefinedClasses: [],

            v2Customers: [],
            v2Vendors: [],
            v2Employees: [],

            creatingTransaction: false,

            showSelectAgainstDialog: false,
            showSelectAgainstDialogOption: undefined,
            // showSelectAgainstDialogForInvoice: false,
            // showSelectAgainstDialogForBill: false,

            cheque: undefined,

            ...this.getEditState()
        }

        if (props.location.defaultTransaction != undefined) {
            this.state = {
                ...this.state,
                ...props.location.defaultTransaction
            }
        }
    }

    isChequeMode() {
        return Util.isNumberExist(parseInt(this.state.pathParams.cqId));
        // return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.directionTypeValue != this.state.directionTypeValue ||
            prevState.otherPartyTypeValue != this.state.otherPartyTypeValue ||
            prevState.otherPartyIdValue != this.state.otherPartyIdValue) {
            this.setState({ againstItemsValue: [] })
        }
    }

    resetToDefaults() {
        let defCashAccount = flattenItems(this.state.accountTree).find(item => !item.group && item.name == "Cash");
        this.setState({
            directionTypeValue: this.isVoucher() ? (this.props.receiptVoucher ? TRANSACTION_DIRECTION_TYPE_INWARD : TRANSACTION_DIRECTION_TYPE_OUTWARD) : TRANSACTION_DIRECTION_TYPE_INWARD,
            otherPartyTypeValue: this.props.paymentVoucher ? TRANSACTION_PARTY_TYPE_SUPPLIER + "" : TRANSACTION_PARTY_TYPE_CUSTOMER,
            otherPartyIdValue: 0,

            modeValue: TRANSACTION_MODE.METHOD,

            cashAccount: defCashAccount,
            againstOfAccount: undefined,
            amountValue: 0,
            paymentMethodsValue: [],
            infoValue: "",

            refNoValue: "",
            transactionDateValue: null,

            approvedByName: "",
            receivedByName: "",
            approvedByIdNo: "",
            receivedByIdNo: "",


            againstItemsValue: [],

            otherPartyIdPickerKey: Util.newTempId(),
            paymentMethodPickerKey: Util.newTempId(),

            ...this.getEditState()
        })
    }

    createTransaction() {
        if (isV2()) {
            if (!Util.isStringExists(this.state.refNoValue)) {
                UIUtil.showInfo("Ref number is required!");
                return;
            }

            if (!Util.isNumberExist(this.state.transactionDateValue)) {
                UIUtil.showInfo("Date is required!");
                return;
            }
        }

        // if (isV2()) {
        //     UIUtil.showInfo("Not initialized")
        //     return;
        // }

        let transaction = {
            directionType: this.state.directionTypeValue,
            otherPartyType: this.state.otherPartyTypeValue,
            otherPartyId: this.state.otherPartyIdValue,

            mode: this.state.modeValue,

            destinationType: this.state.destinationTypeValue,
            destinationId: this.state.destinationIdValue,

            againstItems: this.state.againstItemsValue,

            amount: this.state.amountValue,
            paymentMethods: this.state.paymentMethodsValue,
            info: this.state.infoValue,
            refNo: this.state.refNoValue,
            transactionDate: this.state.transactionDateValue,

            requestedCashAccountId: this.state.cashAccount?.id,
            requestedAgainstOfAccountId: this.state.againstOfAccount?.id,

            tags: this.state.tagsValue.map(val => ({ ...val, name: val.label })),

            approvedByName: this.state.approvedByName,
            receivedByName: this.state.receivedByName,
            approvedByIdNo: this.state.approvedByIdNo,
            receivedByIdNo: this.state.receivedByIdNo,
        }

        if (this.isChequeMode()) {
            transaction = {
                ...transaction,

                directionType: this.state.cheque.type == CHEQUE_TYPE_RECEIVABLE ? TRANSACTION_DIRECTION_TYPE_INWARD : TRANSACTION_DIRECTION_TYPE_OUTWARD,

                chequeId: this.state.cheque.id,
                amount: this.state.cheque.amount,
                paymentMethods: [
                    {
                        methodType: PAYMENT_METHOD_CHEQUE,
                        paymentId: this.state.cheque.recordId,
                        amount: this.state.cheque.amount,
                        chequeId: this.state.cheque.id,
                    }
                ]
            }
        }

        if (this.state.modeValue == TRANSACTION_MODE.JOURNAL) {
            transaction = {
                ...transaction,
                // REPEATED IN journal entry creator page
                requestedJrAmounts: this.state.jrAmountsValue.map(amount => ({
                    accountLedgerId: amount.account.id,
                    subsidiaryLedgerId: amount.subsidiaryAccount ? amount.subsidiaryAccount.id : 0,
                    subsidiaryLedgerType: amount.subsidiaryAccount ? amount.subsidiaryLedgerType : -1,
                    amount: amount.amount,
                    type: amount.type,
                    narration: amount.narration,
                    //amountTags: (amount.amountTags ?? []).map(tag => ({ name: tag.label }))
                    amountTags: amount.amountTags
                }))
            }
        }

        if (this.props.courier && this.props.accountsPayableId) {
            transaction.requestedAgainstOfAccountId = this.props.accountsPayableId;
        }

        this.setState({ creatingTransaction: true });
        const listener = response => {
            this.setState({ creatingTransaction: false });
            if (response.status === true) {
                UIUtil.showSuccess();
                this.props.history.replace("/transaction/" + response.payload + "?temp=" + Math.random())
            } else {
                UIUtil.showError(response.message)
            }
        }
        if (this.editMode) {
            Api.updateTransaction({ ...transaction, id: this.editMode.id }, listener)
        } else {
            Api.createTransaction(transaction, listener)
        }
    }

    onPageStart() {
        if (this.isChequeMode()) {
            Api.getCheque(this.state.pathParams.cqId, response => {
                if (response.status === true) {
                    const cheque = response.payload;
                    this.setState({ cheque: response.payload, amountValue: response.payload.amount })

                    switch (cheque.otherPartyType) {
                        case CHEQUE_OTHER_PARTY_TYPE_SUPPLIER:
                            this.setState({ otherPartyTypeValue: TRANSACTION_PARTY_TYPE_SUPPLIER + "", otherPartyIdValue: cheque.supplierId })
                            break;
                        case CHEQUE_OTHER_PARTY_TYPE_CUSTOMER:
                            this.setState({ otherPartyTypeValue: TRANSACTION_PARTY_TYPE_CUSTOMER, otherPartyIdValue: cheque.customerId })
                            break;
                        case CHEQUE_OTHER_PARTY_TYPE_STAFF:
                            this.setState({ otherPartyTypeValue: TRANSACTION_PARTY_TYPE_STAFF, otherPartyIdValue: cheque.staffId })
                            break;
                        case CHEQUE_OTHER_PARTY_TYPE_VENDOR:
                            this.setState({ otherPartyTypeValue: TRANSACTION_PARTY_TYPE_VENDOR, otherPartyIdValue: cheque.vendorId })
                            break;
                        case CHEQUE_OTHER_PARTY_TYPE_OTHER:
                            this.setState({ otherPartyTypeValue: TRANSACTION_PARTY_TYPE_OTHER })
                            break;
                    }

                    this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => {
                        let defCashAccount = Util.isNumberExist(cheque.bankAccountId) ? flattenItems(getCashGroup(payload.accountTree[0]).items).find(item => item.id === cheque.bankAccountId) : null;
                        if (!defCashAccount) {
                            defCashAccount = flattenItems(payload.accountTree).find(item => item.name == "Cash" && !item.group)
                        }

                        return {
                            suppliers: payload.suppliers,
                            customers: payload.customers,
                            staff: payload.staff,
                            accountTree: payload.accountTree,
                            vendors: payload.vendors,
                            stores: payload.stores,
                            venues: payload.venues,
                            students: payload.students,
                            tutors: payload.tutors,
                            movieDistributors: payload.movieDistributors,
                            predefinedClasses: payload.predefinedClasses,

                            v2Customers: payload.v2Customers,
                            v2Vendors: payload.v2Vendors,
                            v2Employees: payload.v2Employees,

                            cashAccount: defCashAccount,
                            againstOfAccount: undefined,

                            endpointsList: payload
                        }
                    })
                } else {
                    this.setState({
                        pageLoading: false,
                        pageInError: true,
                        pageInErrorTitle: undefined,
                        pageInErrorMessage: undefined,
                        pageInError404: false,
                    })
                }
            })
        } else {
            this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => {
                let defCashAccount = flattenItems(payload.accountTree).find(item => item.name == "Cash" && !item.group);
                // mainLoop: for (const group of payload.accountTree) {
                //     for (const subGroup of group.items) {
                //         for (const item of subGroup.items) {
                //             if (item.name == "Cash") {
                //                 defCashAccount = item;
                //                 break mainLoop;
                //             }
                //         }
                //     }
                // }

                return {
                    suppliers: payload.suppliers,
                    customers: payload.customers,
                    staff: payload.staff,
                    accountTree: payload.accountTree,
                    vendors: payload.vendors,
                    stores: payload.stores,
                    venues: payload.venues,
                    students: payload.students,
                    tutors: payload.tutors,
                    movieDistributors: payload.movieDistributors,
                    predefinedClasses: payload.predefinedClasses,

                    v2Customers: payload.v2Customers,
                    v2Vendors: payload.v2Vendors,
                    v2Employees: payload.v2Employees,

                    cashAccount: defCashAccount,
                    againstOfAccount: undefined,

                    endpointsList: payload
                }
            })
        }
    }

    canCreate() {
        if (this.state.modeValue == TRANSACTION_MODE.METHOD) {
            if (this.state.paymentMethodsValue.length == 0) {
                if (!this.isChequeMode()) {
                    return false;
                }
            }
        }

        if (this.state.modeValue == TRANSACTION_MODE.JOURNAL) {
            if (!this.state.againstOfAccount) {
                return false;
            }

            if (this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT) <= 0) {
                return false;
            }

            if (this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT) != this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)) {
                return false;
            }

            for (const amount of this.state.jrAmountsValue) {
                if (amount.account == undefined) {
                    return false;
                }

                if (amount.subsidiaryLedgerType !== null && amount.subsidiaryLedgerType !== undefined && amount.subsidiaryLedgerType != -1 && !amount.subsidiaryAccount) {
                    return false;
                }
            }

        }

        if (isNaN(this.state.amountValue) || this.state.amountValue == "") {
            if (!this.isChequeMode()) {
                return false;
            }
        }

        if (this.getPartyTypeList() !== undefined && !Util.isNumberExist(this.state.otherPartyIdValue)) {
            return false;
        }

        if (this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_OTHER && !Util.isNumberExist(this.state.otherPartyIdValue)) {
            return false;
        }


        let totalPaid = 0;
        for (const paymentMethod of this.state.paymentMethodsValue) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                totalPaid += parseFloat(paymentMethod.amount);
            }

            // if (!isNaN(paymentMethod.processingFeeAmount) && paymentMethod.processingFeeAmount != '') {
            //     totalPaid += parseFloat(paymentMethod.processingFeeAmount)
            // }
        }
        if (this.state.modeValue == TRANSACTION_MODE.METHOD) {
            if (totalPaid != this.state.amountValue) {
                if (!this.isChequeMode()) {
                    return false;
                }
            }
        }

        return true;
    }

    isInward() {
        if (this.isChequeMode()) {
            return this.state.cheque.type == CHEQUE_TYPE_RECEIVABLE;
        } else {
            return this.state.directionTypeValue == TRANSACTION_DIRECTION_TYPE_INWARD;
        }
    }

    getAgainstOptions() {
        if (Util.isNumberExist(this.state.otherPartyIdValue)) {
            return createAgainstOptions(this.isInward(), this.state.otherPartyTypeValue)
        } else {
            return []
        }
    }

    // getAgainstMode() {
    //     if (this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_CUSTOMER && Util.isNumberExist(this.state.otherPartyIdValue)) {
    //         return AGAINST_MODE.sales;
    //     } else if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_SUPPLIER && Util.isNumberExist(this.state.otherPartyIdValue)) {
    //         return AGAINST_MODE.purchase;
    //     } else if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_VENDOR && Util.isNumberExist(this.state.otherPartyIdValue)) {
    //         return AGAINST_MODE.bills;
    //     } else if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_VENUE && Util.isNumberExist(this.state.otherPartyIdValue)) {
    //         return AGAINST_MODE.bills;
    //     } else if (this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_VENUE && Util.isNumberExist(this.state.otherPartyIdValue)) {
    //         return AGAINST_MODE.venueSales;
    //     } else {
    //         return undefined;
    //     }
    // }

    shouldRenderAgainstSection() {


        return !this.editMode && !this.isCustomerAdvanceMode && this.getAgainstOptions().length > 0;
        // if (this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_CUSTOMER && Util.isNumberExist(this.state.otherPartyIdValue)) {
        //     return true;
        // } else if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_SUPPLIER && Util.isNumberExist(this.state.otherPartyIdValue)) {
        //     return true;
        // } else if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_VENDOR && Util.isNumberExist(this.state.otherPartyIdValue)) {
        //     return true;
        // } else if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_VENUE && Util.isNumberExist(this.state.otherPartyIdValue)) {
        //     return true;
        // } else if (this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_VENUE && Util.isNumberExist(this.state.otherPartyIdValue)) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    getCashAccounts() {
        return getCashGroup(this.state.accountTree[0]).items;
        //return this.state.accountTree[0].items.find(item => item.name == "Cash and cash equivalents").items


        // const accountTree = flattenItems(this.state.accountTree);
        // const cashGroup = accountTree.find(item => item.group && item.name == "Cash and cash equivalents");
        // console.log(accountTree.filter(item => item.parentId == cashGroup.id))

        // const accountTree = [...this.state.accountTree].filter(item => item.id == LEDGER_TYPE_ASSET);
        // for (const group of accountTree) {
        //     group.items = group.items.filter(item => item.name == "Cash and cash equivalents");
        // }

        // return accountTree.filter(item => item.parentId == cashGroup.id);
    }

    getAgainstOfAccount() {
        if (this.isInward()) {
            return getReceivableGroup(this.state.accountTree[0]).items;
        } else {
            return getPayableGroup(this.state.accountTree[1]).items;
        }
    }

    // getPayableAccounts() {
    //     return getPayableGroup(this.state.accountTree[1]).items;
    // }

    isAgainstSale() {
        return this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_CUSTOMER && Util.isNumberExist(this.state.otherPartyIdValue);
    }

    isAgainstPurchase() {
        return !this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_SUPPLIER && Util.isNumberExist(this.state.otherPartyIdValue);
    }

    renderDirection() {
        return <p style={{ fontSize: 14, fontWeight: 'bold', color: this.isInward() ? 'green' : 'red' }}>{this.isInward() ? "Inward (receipt)" : "Outward (payment)"}</p>;
    }

    renderDirectionSelector() {
        return (
            <div>
                <ContentSwitcher selectedIndex={this.state.directionTypeValue} onChange={({ name }) => {
                    if (this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_OTHER) {
                        this.setState({ directionTypeValue: name, otherPartyIdValue: 0, otherPartyIdObject: undefined })
                    } else {
                        this.setState({ directionTypeValue: name })
                    }
                }}>
                    <Switch name={TRANSACTION_DIRECTION_TYPE_INWARD} text="Inward (receipt)" />
                    <Switch name={TRANSACTION_DIRECTION_TYPE_OUTWARD} text="Outward (payment)" />
                </ContentSwitcher>
            </div>
        )
    }

    renderMode() {
        return (
            <div>
                <ContentSwitcher selectedIndex={this.state.modeValue} onChange={({ name }) => {
                    let defCashAccount = flattenItems(this.state.accountTree).find(item => !item.group && item.name == "Cash");
                    this.setState({
                        modeValue: name,
                        cashAccount: defCashAccount,
                        againstOfAccount: undefined,
                        tagsValue: [],
                        paymentMethodsValue: [],
                    })
                }}>
                    <Switch name={TRANSACTION_MODE.METHOD} text="Payment Method" />
                    <Switch name={TRANSACTION_MODE.JOURNAL} text="Journal Entry" />
                </ContentSwitcher>
            </div>
        )
    }

    renderTransactionRecordInfo() {
        let transactionDateValue = this.state.transactionDateValue;
        if (!Util.isNumberExist(transactionDateValue)) {
            transactionDateValue = undefined;
        }

        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    <TextInput
                        labelText={"Ref Number"}
                        value={this.state.refNoValue}
                        onChange={e => this.setState({ refNoValue: e.target.value })}
                    />
                </div>

                <div style={{ marginBottom: '1rem' }}>
                    {/* <label className="bx--label">{labelText}</label> */}
                    <DatePicker datePickerType="single"
                        dateFormat="d/m/Y"
                        key={this.state.supplierDateKey}
                        value={transactionDateValue}
                        onChange={e => {
                            if (e.length > 0) {
                                this.setState({
                                    transactionDateValue: e[0].getTime(),
                                })
                            } else {
                                this.setState({
                                    transactionDateValue: undefined
                                })
                            }
                        }}
                    >
                        <DatePickerInput
                            // placeholder="mm/dd/yyyy"
                            placeholder="dd/mm/yyyy"
                            labelText={"Date"}
                        />
                    </DatePicker>
                </div>

                <div style={{ marginBottom: '1rem', display: 'flex', gap: '0.5rem', }}>
                    <TextInput style={{ flex: 1 }}
                        labelText={"Approved by name (optional)"}
                        value={this.state.approvedByName}
                        onChange={e => this.setState({ approvedByName: e.target.value })}
                    />
                    <TextInput style={{ flex: 1 }}
                        labelText={"Approved by ID No (optional)"}
                        value={this.state.approvedByIdNo}
                        onChange={e => this.setState({ approvedByIdNo: e.target.value })}
                    />
                    <TextInput style={{ flex: 1 }}
                        labelText={"Received by name (optional)"}
                        value={this.state.receivedByName}
                        onChange={e => this.setState({ receivedByName: e.target.value })}
                    />
                    <TextInput style={{ flex: 1 }}
                        labelText={"Received by ID No (optional)"}
                        value={this.state.receivedByIdNo}
                        onChange={e => this.setState({ receivedByIdNo: e.target.value })}
                    />
                </div>
            </>
        )
    }

    renderSelectOtherParty() {
        return (
            <div>
                {!this.editMode && <>
                    {isV2() ? (<>

                        <TileGroup className="horizontal-tile-radio centered-content-tile"
                            valueSelected={this.state.otherPartyTypeValue} onChange={value => this.setState({ otherPartyTypeValue: value, otherPartyIdValue: 0, otherPartyIdObject: undefined, otherPartyIdPickerKey: Util.newTempId() })}>
                            <RadioTile value={TRANSACTION_PARTY_TYPE_V2_VENDOR}>
                                <RadioItem icon={Delivery32} title="Vendor" desc={this.isInward() ? "Receive payment from vendor" : "Make payment to vendor"} />
                            </RadioTile>
                            <RadioTile value={TRANSACTION_PARTY_TYPE_V2_CUSTOMER}>
                                <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={this.isInward() ? "Receive payment from customer" : "Make payment to customer"} />
                            </RadioTile>
                            <RadioTile value={TRANSACTION_PARTY_TYPE_V2_EMPLOYEE}>
                                <RadioItem icon={User32} title="Employee" desc={this.isInward() ? "Receive payment from employee" : "Make payment to employee"} />
                            </RadioTile>
                            <RadioTile value={TRANSACTION_PARTY_TYPE_OTHER}>
                                <RadioItem icon={Launch32} title="Other" desc="Create transaction" />
                            </RadioTile>
                        </TileGroup>

                    </>) : (<>

                        {!this.isCustomerAdvanceMode &&
                            <TileGroup className="horizontal-tile-radio centered-content-tile"
                                valueSelected={this.state.otherPartyTypeValue} onChange={value => this.setState({ otherPartyTypeValue: value, otherPartyIdValue: 0, otherPartyIdObject: undefined, otherPartyIdPickerKey: Util.newTempId() })}>
                                <RadioTile value={TRANSACTION_PARTY_TYPE_SUPPLIER + ""}>
                                    <RadioItem icon={Delivery32} title="Supplier" desc={this.isInward() ? "Receive payment from supplier" : "Make payment to supplier"} />
                                </RadioTile>
                                <RadioTile value={TRANSACTION_PARTY_TYPE_CUSTOMER}>
                                    <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={this.isInward() ? "Receive payment from customer" : "Make payment to customer"} />
                                </RadioTile>
                                {hasCapabilitySupport("lms") &&
                                    <RadioTile value={TRANSACTION_PARTY_TYPE_STUDENT}>
                                        <RadioItem icon={Education32} title="Student" desc={this.isInward() ? "Receive payment from student" : "Make payment to student"} />
                                    </RadioTile>}
                                {hasCapabilitySupport("lms") &&
                                    <RadioTile value={TRANSACTION_PARTY_TYPE_TUTOR}>
                                        <RadioItem icon={UserSpeaker32} title="Tutor" desc={this.isInward() ? "Receive payment from tutor" : "Make payment to tutor"} />
                                    </RadioTile>}
                                {hasCapabilitySupport("cinema") &&
                                    <RadioTile value={TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR}>
                                        <RadioItem icon={BuildingInsights_332} title="Distributor" desc={this.isInward() ? "Receive payment from movie distributor" : "Make payment to movie distributor"} />
                                    </RadioTile>}
                                <RadioTile value={TRANSACTION_PARTY_TYPE_VENDOR}>
                                    <RadioItem icon={Enterprise32} title="Vendor" desc={this.isInward() ? "Receive payment from vendor" : "Make payment to vendor"} />
                                </RadioTile>
                                <RadioTile value={TRANSACTION_PARTY_TYPE_STAFF}>
                                    <RadioItem icon={User32} title="Staff" desc={this.isInward() ? "Receive payment from staff" : "Make payment to staff"} />
                                </RadioTile>
                                {hasCapabilitySupport("thirdPartyPos") &&
                                    <RadioTile value={TRANSACTION_PARTY_TYPE_VENUE}>
                                        <RadioItem icon={Building32} title="Venue" desc={this.isInward() ? "Receive payment from venue" : "Make payment to venue"} />
                                    </RadioTile>}
                                <RadioTile value={TRANSACTION_PARTY_TYPE_OTHER}>
                                    <RadioItem icon={Launch32} title="Other" desc="Create transaction" />
                                </RadioTile>
                            </TileGroup>}

                    </>)}

                </>}





                {!this.editMode && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_OTHER && <>
                    <div style={{ height: '1rem' }} />
                    <label className="bx--label">{this.isInward() ? 'Revenue' : 'Payment'} Account</label>
                    <div style={{ height: 40 }}>
                        <CustomComboBox
                            key={this.isInward() + '-rpabox'}
                            items={this.state.accountTree.filter(item => item.id == (this.isInward() ? LEDGER_TYPE_INCOME : LEDGER_TYPE_EXPENSE))[0].items}
                            selectedItem={this.state.otherPartyIdObject}
                            onSelectedItemUpdate={item => this.setState({ otherPartyIdValue: item !== undefined ? item.id : 0, otherPartyIdObject: item })}
                        />
                    </div>
                </>}

                {this.getPartyTypeList() !== undefined && this.state.otherPartyTypeValue != TRANSACTION_PARTY_TYPE_CUSTOMER && <>
                    <div style={{ height: '1rem' }} />
                    <ComboBox
                        key={this.state.otherPartyIdPickerKey}
                        titleText={this.getPartyTypeName()}
                        items={this.getPartyTypeList()}
                        itemToString={item => item !== null ? item.value : ""}
                        selectedItem={this.getPartyTypeList().filter(item => item.id == this.state.otherPartyIdValue)[0]}
                        onChange={e => {
                            if (e.selectedItem === null) {
                                this.setState({ otherPartyIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, otherPartyIdPickerKey: Util.newTempId(), })
                            } else {
                                this.setState({ otherPartyIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                            }
                        }}
                    />
                </>}

                {this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_CUSTOMER && <>
                    <div style={{ height: '1rem' }} />
                    <CustomDialogSelectField value={this.state.otherPartyIdValue} onChange={otherPartyIdValue => this.setState({ otherPartyIdValue })} />
                </>}
            </div>
        )
    }

    renderTransactionAmount() {
        return (
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <p>AED</p>
                <NumberInput hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.amountValue} onChange={e => this.setState({ amountValue: e.target.value < 0 ? 0 : e.target.value }, () => this.calculateAgainstItemAmountDivision())} />
            </div>
        )
    }

    renderPaymentMethods() {
        return <TransactionEditor key={this.state.paymentMethodPickerKey}
            onUpdate={paymentMethods => this.setState({ paymentMethodsValue: paymentMethods })}
            defaultPaymentMethods={this.editMode ? this.state.paymentMethodsValue : undefined}
            currency={"AED"}
            noFocus={this.editMode}
            incoming={this.state.directionTypeValue == TRANSACTION_DIRECTION_TYPE_INWARD}
            totalAmount={(!isNaN(this.state.amountValue) && this.state.amountValue != "") ? parseFloat(this.state.amountValue) : 0} />
    }

    getTotalAmount(type) {
        let total = 0;
        for (const amount of this.state.jrAmountsValue) {
            if (amount.type == type) {
                total += parseFloat(amount.amount);
            }
        }

        if (!this.isInward() && type == JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT) {
            total += parseFloat(this.state.amountValue)
        } else if (this.isInward() && type == JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT) {
            total += parseFloat(this.state.amountValue)
        }

        return parseFloat(total.toFixed(2));
    }

    renderJournalEntry() {
        return <JournalEntryEditor
            key={this.state.journalEditorKey}
            topline={{
                account: this.state.againstOfAccount,
                amount: this.state.amountValue,
                amountType: !this.isInward() ? JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT : JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT
            }}
            noDate
            defaultEntries={this.state.jrAmountsValue}
            onEntriesUpdate={entries => this.setState({ jrAmountsValue: entries })}
            totalDebit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_DEBIT)}
            totalCredit={this.getTotalAmount(JOURNAL_ENTRY_AMOUNT_TYPE_CREDIT)}
            accountTree={this.state.accountTree}
            endpointsList={this.state.endpointsList} />
    }

    renderInformation() {
        return (
            <TextArea placeholder="Note here..." value={this.state.infoValue} onChange={e => this.setState({ infoValue: e.target.value })} />
        )
    }

    isVoucher() {
        return this.props.receiptVoucher || this.props.paymentVoucher
    }

    get isCustomerAdvanceMode() {
        const role = getAccountRole();
        return hasCapabilitySupport("restaurant") && (role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_CASHIER);
    }

    getSubtitle() {
        if (this.isChequeMode()) {
            return "Depositing Cheque";
        }

        if (this.isVoucher()) {
            return this.props.receiptVoucher ? "Receipt Voucher" : "Payment Voucher";
        } else {
            return "Initiating";
        }
    }

    isSelectAgainstSelectionsSelected(item) {
        for (const o of this.state.selectAgainstSelections) {
            if (item.id == o.id) {
                return true;
            }
        }
        return false;
    }

    renderCurrentAgainstOption() {
        const item = this.state.againstItemsValue[0].targetAgainstOption;
        return (<>
            <Button key={item.id + '-clear-btn'} kind="danger--ghost" renderIcon={RowDelete32} onClick={() => this.setState({ againstItemsValue: [] }, () => this.calculateAgainstItemAmountDivision())}>
                Clear Selection
            </Button>

            <Button
                key={item.id}
                renderIcon={item.icon}
                onClick={() => this.setState({
                    showSelectAgainstDialog: true,
                    showSelectAgainstDialogOption: item
                })}
                kind="secondary">Select {item.name}</Button>
        </>)
    }

    renderAgainstSection() {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem', gap: '0.15rem' }}>

                    {this.state.againstItemsValue.length > 0 &&
                        <Button key={this.state.againstItemsValue[0].targetAgainstOption.id + '-clear-btn'} kind="danger--ghost" renderIcon={RowDelete32} onClick={() => this.setState({ againstItemsValue: [] }, () => this.calculateAgainstItemAmountDivision())}>
                            Clear Selection
                        </Button>}

                    {this.getAgainstOptions().map(item => (
                        <Button
                            key={item.id}
                            renderIcon={item.icon}
                            onClick={() => this.setState({
                                showSelectAgainstDialog: true,
                                showSelectAgainstDialogOption: item
                            })}
                            kind="secondary">Select {item.name}</Button>
                    ))}


                    {/* {this.state.againstItemsValue.length > 0 ? (<>
                        {this.renderCurrentAgainstOption()}
                    </>) :
                        this.getAgainstOptions().map(item => (
                            <Button
                                key={item.id}
                                renderIcon={item.icon}
                                onClick={() => this.setState({
                                    showSelectAgainstDialog: true,
                                    showSelectAgainstDialogOption: item
                                })}
                                kind="secondary">Select {item.name}</Button>
                        ))} */}
                </div>

                {this.state.againstItemsValue.map(item => (
                    <AgainstItem key={item.targetId + "-against"} againstItem={item}
                        //stockFlowType={this.getAgainstMode().name}
                        onRemove={() => this.setState(prevState => ({ againstItemsValue: prevState.againstItemsValue.filter(o => o != item) }))}
                    />
                ))}
            </div>
        )
    }

    calculateAgainstItemAmountDivision() {
        this.setState(prevState => {
            let amount = Util.isNumberExist(prevState.amountValue) ? parseFloat(prevState.amountValue) : 0;

            return {
                againstItemsValue: prevState.againstItemsValue.map(item => {
                    // console.log(item)

                    let amountLeft = item.targetItem.amount - item.targetItem.amountPaid;
                    let amountToUse = 0;

                    if (amount >= amountLeft) {
                        amountToUse = amountLeft;
                        amount -= amountLeft;
                    } else {
                        amountToUse = amount;
                        amount = 0;
                    }

                    return {
                        ...item,
                        dividedAmount: amountToUse
                    }
                })
            }
        });
    }

    isIrregularFlow() {
        if (hasCapabilitySupport("shipping") || hasCapabilitySupport("restaurant")) {
            if (!this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_CUSTOMER) {
                return true;
            } else if (this.isInward() && this.state.otherPartyTypeValue == TRANSACTION_PARTY_TYPE_SUPPLIER) {
                return true;
            }
        }

        return false;
    }

    getLayout() {
        if (this.isChequeMode()) {
            //const shouldHaveCashSelection = !Util.isNumberExist(this.state.cheque.bankAccountId)
            const shouldHaveCashSelection = true
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                    <div style={{ width: '75vw' }}>
                        <DLink onClick={() => this.props.history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back</DLink>
                        <h1>Transaction</h1>
                        <p style={{ fontSize: 18 }}>{this.getSubtitle()}</p>

                        <Section icon={Number_132} title={"Cheque"}>
                            <h6 style={{ fontSize: 12 }}>Cheque ID</h6>
                            <p>{this.state.cheque.recordId}</p>

                            <h6 style={{ marginTop: '1rem', fontSize: 12 }}>Bank Name</h6>
                            <p>{this.state.cheque.bankName}</p>

                            <h6 style={{ marginTop: '1rem', fontSize: 12 }}>Amount</h6>
                            <p>AED {this.state.cheque.amount.toFixed(2)}</p>

                            <h6 style={{ marginTop: '1rem', fontSize: 12 }}>Type</h6>
                            <p style={{ color: this.state.cheque.type == CHEQUE_TYPE_RECEIVABLE ? 'green' : 'red' }}>{this.state.cheque.type == CHEQUE_TYPE_RECEIVABLE ? 'Receivable' : 'Payable'}</p>

                            <h6 style={{ marginTop: '1rem', fontSize: 12 }}>{this.getPartyTypeName()}</h6>
                            <p>{this.getPartyTypeList().filter(item => item.id == this.state.otherPartyIdValue)[0]?.value}</p>
                        </Section>

                        {shouldHaveCashSelection && <Section icon={Number_232} title="Accounting">
                            {!Util.isNumberExist(this.state.cheque.bankAccountId) && <>
                                <label className="bx--label">Cash Account</label>
                                <div style={{ height: 40, }}>
                                    <CustomComboBox
                                        defExtended
                                        preventClear
                                        items={this.getCashAccounts()}
                                        selectedItem={this.state.cashAccount}
                                        onSelectedItemUpdate={item => this.setState({ cashAccount: item })}
                                    />
                                </div>
                            </>}

                            {!this.isIrregularFlow() && <>
                                <div style={{ height: '1rem' }} />
                                <label className="bx--label">Against of Account</label>
                                <div style={{ height: 40, }}>
                                    <CustomComboBox
                                        defExtended
                                        preventClear
                                        items={this.getAgainstOfAccount()}
                                        selectedItem={this.state.againstOfAccount}
                                        onSelectedItemUpdate={item => this.setState({ againstOfAccount: item })}
                                    />
                                </div>
                            </>}

                            <div style={{ height: '1rem' }} />
                            <label className="bx--label">Class</label>
                            <div style={{ height: 40, }}>
                                <AmountTagSelectorNormalField options={this.state.predefinedClasses} value={this.state.tagsValue} setValue={tagsValue => this.setState({ tagsValue })} />
                            </div>
                        </Section>}


                        {this.shouldRenderAgainstSection() &&
                            <Section icon={shouldHaveCashSelection ? Number_332 : Number_232} title={`Against of (optional)`}>
                                {this.renderAgainstSection()}
                            </Section>}



                        <Section icon={this.shouldRenderAgainstSection() && shouldHaveCashSelection ? Number_432 : (this.shouldRenderAgainstSection() || shouldHaveCashSelection) ? Number_332 : Number_232} title="Additional Information (optional)">
                            {this.renderInformation()}
                        </Section>


                        <Section icon={this.shouldRenderAgainstSection() && shouldHaveCashSelection ? Number_532 : (this.shouldRenderAgainstSection() || shouldHaveCashSelection) ? Number_432 : Number_332} title="Confirm" extraTopMargin>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => this.createTransaction()} disabled={!this.canCreate()} loading={this.state.creatingTransaction} renderIcon={Currency16}>
                                    {this.editMode ? 'Save Edits' : 'Create Transaction'}
                                </Button>
                            </div>
                        </Section>
                    </div>

                    {this.state.showSelectAgainstDialog &&
                        <TransactionAgainstSelectionDialog
                            // invoiceMode={this.state.showSelectAgainstDialogForInvoice}
                            // billMode={this.state.showSelectAgainstDialogForBill}
                            // againstMode={this.getAgainstMode()} 
                            againstOption={this.state.showSelectAgainstDialogOption}

                            againstParentId={this.state.otherPartyIdValue}
                            defaultSelection={this.state.againstItemsValue}

                            // onConfirm={items => this.setState({
                            //     showSelectAgainstDialog: false, againstItemsValue: items.map(stockFlow => ({
                            //         stockFlowId: stockFlow.id,
                            //         dividedAmount: 0,
                            //         stockFlow
                            //     }))
                            // }, () => this.calculateAgainstItemAmountDivision())}
                            onConfirm={(items, againstOption) => this.setState({
                                showSelectAgainstDialog: false,
                                showSelectAgainstDialogOption: undefined,
                                //showSelectAgainstDialogForInvoice: false, showSelectAgainstDialogForBill: false, 
                                againstItemsValue:
                                    items.map(item => ({
                                        // stockFlowId: (invoiceMode || billMode) ? 0 : item.id,
                                        // invoiceDocumentId: invoiceMode ? item.id : 0,
                                        // billId: billMode ? item.id : 0,

                                        [againstOption.selectionIdParam]: item.id,
                                        dividedAmount: 0,

                                        targetId: item.id,
                                        targetItem: item,
                                        targetAgainstOption: againstOption
                                    }))
                            }, () => this.calculateAgainstItemAmountDivision())}
                            onClose={() => this.setState({ showSelectAgainstDialog: false })} />}
                </div>
            )
        }

        if (this.props.courier) {
            return (
                <div style={{ paddingInline: '3rem', paddingBlock: '6rem', width: '100%' }}>
                    <h1>Courier Receipt</h1>

                    <div style={{ height: '1rem' }} />
                    <Section icon={Number_132} title={"Select Courier"}>
                        <ComboBox
                            key={this.state.otherPartyIdPickerKey}
                            titleText={this.getPartyTypeName()}
                            items={this.getPartyTypeList()}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.getPartyTypeList().filter(item => item.id == this.state.otherPartyIdValue)[0]}
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ otherPartyIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, otherPartyIdPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ otherPartyIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }}
                        />
                    </Section>

                    <Section icon={Number_232} title="Record Info">
                        {this.renderTransactionRecordInfo()}
                    </Section>

                    <Section icon={Number_332} title="Amount">
                        {this.renderTransactionAmount()}
                        <div style={{ height: '1rem' }} />
                        <label className="bx--label">Cash Account</label>
                        <div style={{ height: 40, }}>
                            <CustomComboBox
                                defExtended
                                preventClear
                                items={this.getCashAccounts()}
                                selectedItem={this.state.cashAccount}
                                onSelectedItemUpdate={item => this.setState({ cashAccount: item })}
                            />
                        </div>

                        <div style={{ height: '1rem' }} />
                        <label className="bx--label">Class</label>
                        <div style={{ height: 40, }}>
                            <AmountTagSelectorNormalField options={this.state.predefinedClasses} value={this.state.tagsValue} setValue={tagsValue => this.setState({ tagsValue })} />
                        </div>
                    </Section>

                    <Section icon={Number_432} title="Payment Methods">
                        {this.renderPaymentMethods()}
                    </Section>


                    <Section icon={Number_532} title="Additional Information (optional)">
                        {this.renderInformation()}
                    </Section>


                    <Section icon={Number_632} title="Confirm" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => this.createTransaction()} disabled={!this.canCreate()} loading={this.state.creatingTransaction} renderIcon={Currency16}>
                                Create Receipt
                            </Button>
                        </div>
                    </Section>
                </div>
            )
        }

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: this.editMode ? 0 : '6rem', paddingBottom: this.editMode ? 0 : '6rem' }}>
                <div style={{ width: '75vw' }}>
                    {!this.editMode && <>
                        <h1>Transaction</h1>
                        <p style={{ fontSize: 18 }}>{this.getSubtitle()}</p>
                    </>}

                    {!this.editMode && <Section icon={Number_132} title={(this.isVoucher()) ? "Direction" : "Select direction"} noMargin={this.editMode}>
                        {(this.isVoucher()) ? (
                            this.renderDirection()
                        ) : this.renderDirectionSelector()}
                    </Section>}

                    {//(this.editMode ? this.state.otherPartyTypeValue != TRANSACTION_PARTY_TYPE_OTHER : true) &&
                        !this.editMode &&
                        <Section icon={Number_232} title={this.isInward() ? "Select payer" : "Select recipient"}>
                            {this.renderSelectOtherParty()}
                        </Section>}

                    <Section icon={Number_332} title="Transaction record info">
                        {this.renderTransactionRecordInfo()}
                    </Section>

                    {!this.editMode && <Section icon={Number_432} title="Set amount">
                        {this.renderTransactionAmount()}
                        {!this.isCustomerAdvanceMode && <>
                            {!this.isIrregularFlow() && <>
                                <div style={{ height: '1rem' }} />
                                <label className="bx--label">Transaction Type</label>
                                {this.renderMode()}
                            </>}

                            {this.state.modeValue === TRANSACTION_MODE.METHOD && <>
                                <div style={{ height: '1rem' }} />
                                <label className="bx--label">Cash Account</label>
                                <div style={{ height: 40, }}>
                                    <CustomComboBox
                                        defExtended
                                        preventClear
                                        items={this.getCashAccounts()}
                                        selectedItem={this.state.cashAccount}
                                        onSelectedItemUpdate={item => this.setState({ cashAccount: item })}
                                    />
                                </div>
                                {!this.isIrregularFlow() && <>
                                    <div style={{ height: '1rem' }} />
                                    <label className="bx--label">Against of Account</label>
                                    <div style={{ height: 40, }}>
                                        <CustomComboBox
                                            defExtended
                                            preventClear
                                            items={this.getAgainstOfAccount()}
                                            selectedItem={this.state.againstOfAccount}
                                            onSelectedItemUpdate={item => this.setState({ againstOfAccount: item })}
                                        />
                                    </div>
                                </>}

                                <div style={{ height: '1rem' }} />
                                <label className="bx--label">Class</label>
                                <div style={{ height: 40, }}>
                                    <AmountTagSelectorNormalField options={this.state.predefinedClasses} value={this.state.tagsValue} setValue={tagsValue => this.setState({ tagsValue })} />
                                </div>
                            </>}

                            {this.state.modeValue === TRANSACTION_MODE.JOURNAL && <>
                                <div style={{ height: '1rem' }} />
                                <label className="bx--label">Against of Account</label>
                                <div style={{ height: 40, }}>
                                    <CustomComboBox
                                        defExtended
                                        preventClear
                                        items={this.getAgainstOfAccount()}
                                        selectedItem={this.state.againstOfAccount}
                                        onSelectedItemUpdate={item => this.setState({ againstOfAccount: item })}
                                    />
                                </div>

                                <div style={{ height: '1rem' }} />
                                <label className="bx--label">Class</label>
                                <div style={{ height: 40, }}>
                                    <AmountTagSelectorNormalField options={this.state.predefinedClasses} value={this.state.tagsValue} setValue={tagsValue => this.setState({ tagsValue })} />
                                </div>
                            </>}
                        </>}
                    </Section>}

                    {this.shouldRenderAgainstSection() &&
                        // <Section icon={Number_432} title={this.isAgainstSale() ? "Against Sales (optional)" : "Against Purchases (optional)"}>
                        // <Section icon={Number_432} title={`Against ${this.getAgainstMode().name} (optional)`}>
                        <Section icon={Number_532} title={`Against of (optional)`}>
                            {this.renderAgainstSection()}
                        </Section>}

                    {this.state.modeValue === TRANSACTION_MODE.METHOD && <>
                        {!this.editMode &&
                            <Section icon={this.shouldRenderAgainstSection() ? Number_632 : Number_532} title="Add payment methods">
                                {this.renderPaymentMethods()}
                            </Section>}
                    </>}
                    {this.state.modeValue === TRANSACTION_MODE.JOURNAL && <>
                        {!this.editMode &&
                            <Section icon={this.shouldRenderAgainstSection() ? Number_632 : Number_532} title="Journal Entry">
                                {this.renderJournalEntry()}
                            </Section>}
                    </>}

                    <Section icon={this.shouldRenderAgainstSection() ? Number_732 : Number_632} title="Additional Information (optional)">
                        {this.renderInformation()}
                    </Section>


                    <Section icon={this.shouldRenderAgainstSection() ? Number_832 : Number_732} title="Confirm" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonSet style={{ width: 392 }}>
                                <Button onClick={() => this.resetToDefaults()} disabled={this.state.creatingTransaction} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                                <Button onClick={() => this.createTransaction()} disabled={!this.canCreate()} loading={this.state.creatingTransaction} renderIcon={Currency16}>
                                    {this.editMode ? 'Save Edits' : 'Create Transaction'}
                                </Button>
                            </ButtonSet>
                        </div>
                    </Section>
                </div>

                {this.state.showSelectAgainstDialog &&
                    <TransactionAgainstSelectionDialog
                        // invoiceMode={this.state.showSelectAgainstDialogForInvoice}
                        // billMode={this.state.showSelectAgainstDialogForBill}
                        // againstMode={this.getAgainstMode()} 
                        againstOption={this.state.showSelectAgainstDialogOption}

                        againstParentId={this.state.otherPartyIdValue}
                        defaultSelection={this.state.againstItemsValue}

                        // onConfirm={items => this.setState({
                        //     showSelectAgainstDialog: false, againstItemsValue: items.map(stockFlow => ({
                        //         stockFlowId: stockFlow.id,
                        //         dividedAmount: 0,
                        //         stockFlow
                        //     }))
                        // }, () => this.calculateAgainstItemAmountDivision())}
                        onConfirm={(items, againstOption) => this.setState(prevState => ({
                            showSelectAgainstDialog: false,
                            showSelectAgainstDialogOption: undefined,
                            againstItemsValue: [
                                ...(prevState.againstItemsValue ? (
                                    prevState.againstItemsValue.filter(o => o.targetAgainstOption?.id !== againstOption?.id)
                                ) : []),
                                ...items.map(item => ({
                                    // stockFlowId: (invoiceMode || billMode) ? 0 : item.id,
                                    // invoiceDocumentId: invoiceMode ? item.id : 0,
                                    // billId: billMode ? item.id : 0,

                                    [againstOption.selectionIdParam]: item.id,
                                    dividedAmount: 0,

                                    targetId: item.id,
                                    targetItem: item,
                                    targetAgainstOption: againstOption
                                }))
                            ]
                        }), () => this.calculateAgainstItemAmountDivision())}
                        onClose={() => this.setState({ showSelectAgainstDialog: false })} />}
            </div>
        )
    }

    getPartyTypeList() {
        switch (this.state.otherPartyTypeValue) {
            case TRANSACTION_PARTY_TYPE_SUPPLIER: case TRANSACTION_PARTY_TYPE_SUPPLIER + "":
                return (this.props.courier && this.props.courierList) ? this.props.courierList : this.state.suppliers;
            case TRANSACTION_PARTY_TYPE_CUSTOMER:
                return this.state.customers;
            case TRANSACTION_PARTY_TYPE_STAFF:
                return this.state.staff;
            case TRANSACTION_PARTY_TYPE_VENDOR:
                return this.state.vendors;
            case TRANSACTION_PARTY_TYPE_STORE:
                return this.state.stores;
            case TRANSACTION_PARTY_TYPE_VENUE:
                return this.state.venues;
            case TRANSACTION_PARTY_TYPE_STUDENT:
                return this.state.students;
            case TRANSACTION_PARTY_TYPE_TUTOR:
                return this.state.tutors;
            case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
                return this.state.movieDistributors;

            case TRANSACTION_PARTY_TYPE_V2_CUSTOMER:
                return this.state.v2Customers;
            case TRANSACTION_PARTY_TYPE_V2_VENDOR:
                return this.state.v2Vendors;
            case TRANSACTION_PARTY_TYPE_V2_EMPLOYEE:
                return this.state.v2Employees;

        }

        return undefined;
    }

    getPartyTypeName() {
        switch (this.state.otherPartyTypeValue) {
            case TRANSACTION_PARTY_TYPE_SUPPLIER: case TRANSACTION_PARTY_TYPE_SUPPLIER + "":
                return this.props.courier ? "Courier" : "Supplier";
            case TRANSACTION_PARTY_TYPE_CUSTOMER:
                return "Customer";
            case TRANSACTION_PARTY_TYPE_STAFF:
                return "Staff";
            case TRANSACTION_PARTY_TYPE_VENDOR:
                return "Vendor";
            case TRANSACTION_PARTY_TYPE_STORE:
                return "Store";
            case TRANSACTION_PARTY_TYPE_VENUE:
                return "Venue";
            case TRANSACTION_PARTY_TYPE_STUDENT:
                return "Student";
            case TRANSACTION_PARTY_TYPE_TUTOR:
                return "Tutor";
            case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
                return "Movie Distributor"

            case TRANSACTION_PARTY_TYPE_V2_CUSTOMER:
                return "Customer";
            case TRANSACTION_PARTY_TYPE_V2_VENDOR:
                return "Vendor";
            case TRANSACTION_PARTY_TYPE_V2_EMPLOYEE:
                return "Employee";
        }

        return undefined;
    }

}

export default withRouter(TransactionCreatorPage);