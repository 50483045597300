import { hasCapabilitySupport } from "../../../app/Capabilities";
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { Product32, ArrowsVertical16, Download16, Upload16, AirlinePassengerCare32, ListBoxes } from '@carbon/icons-react'
import { ComposedModal } from "carbon-components-react";
import { FormDialog } from "../../../templates/form/form-dialog";
import Api from "../../../session/Api";
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select";
import { useStringValueFilter } from "../../engine/useValueFilter";

export const V1CustomerList = {
    filter: Filter,
    icon: AirlinePassengerCare32,

    customPath: "/customer-list-rpt",
    itemToOpenLink: item => `/customers/${item.id}`,
    isListMode: true,

    isAvailable: () => {
        const role = getAccountRole();
        return (role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR);
    }
}


function Filter({ endpoint, state }) {
    return (<>


        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Name" property="fullName" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Phone" property="phoneNumber" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Email" property="email" fullWidth />
        </div>


        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 2 }}>
            <MultiSelectListMode isMulti={false} placeholder="Customer Group" fieldKey="customerListCustomerGroup" options={endpoint.customerGroups.map(item => item.value)} state={state} property="customerGroup" />
        </div>

    </>)
}
